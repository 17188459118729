import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BreadcrumbsModule } from './shared/breadcrumbs/breadcrumbs.module';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PageLayoutModule } from './shared/page-layout/page-layout.module';
import { PageHeaderButtonsModule } from './shared/page-header-buttons/page-header-buttons.module';
import { DialogsModule } from './shared/dialog/dialogs.module';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { FuryAlertBoxModule } from './shared/alert-box/alert-box.module';
import { ReportPanelModule } from './shared/report-panel/report-panel.module';
import { RemovableAttachmentModule } from './shared/removable-attachment/removable-attachment.module';
import { TableFiltersModule } from './shared/table-filters/table-filters/table-filters.module';
import { MatCalendarHeaderModule } from './shared/calendar/header/header.module';
import { WasteOrderPanelModule } from './shared/waste-order-panel/waste-order-panel.module';
import { DatetimeAdapter } from '@ng-matero/extensions/core';
import { MomentDatetimeAdapter } from '@ng-matero/extensions-moment-adapter';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  exports: [
    BreadcrumbsModule,
    SidebarModule,
    RouterModule,
    PageLayoutModule,
    PageHeaderButtonsModule,
    TableFiltersModule,
    DialogsModule,
    FuryAlertBoxModule,
    ReportPanelModule,
    WasteOrderPanelModule,

    // External
    FlexLayoutModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    FontAwesomeModule,
    ScrollingModule,
    MaterialFileInputModule,
    RemovableAttachmentModule,
    MatCalendarHeaderModule,
    MatNativeDateModule,
  ],
/*
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'DD-MM-YY'
        },
        display: {
          dateInput: 'DD-MM-YY',
          monthLabel: 'MMMM',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'MMMM',
          monthYearA11yLabel: 'YYYY'
        }
      }
    },
    {
      provide: DatetimeAdapter,
      useClass: MomentDatetimeAdapter,
    },
    {provide: DateAdapter, useClass: MomentDateAdapter},
  ],
*/
})
export class FurySharedModule {
}
