import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { Organisation } from '../../interfaces/routering/organisation';

@Injectable()

export class OrganisationService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(): Observable<Organisation[] | boolean> {
    return new Observable<Organisation[] | boolean>((o: Subscriber<Organisation[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('routering.organisations.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Organisation[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<Organisation | boolean> {
    return new Observable<Organisation | boolean>((o: Subscriber<Organisation | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('routering.organisations.read', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Organisation>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('routering.organisations.create'), formData);
  }

  sort(formData: any): Observable<ServerResponse> {
    return this.api.sort(this.apiEndpoints.get('routering.organisations.sort'), formData);
  }

  update(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('routering.organisations.update', {':id': id}), formData);
  }

  switch(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('routering.organisations.switch', {':id': id}));
  }

  delete(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('routering.organisations.delete', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('routering.organisations.restore', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }
}
