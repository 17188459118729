import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';

@Injectable()

export class WasteCalendarService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  get(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('waste-calendar.waste-calendar'), formData);
  }
}
