import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';
import { AuthenticationService } from './services/base/authentication.service';
import { User } from './interfaces/routering/user';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppUpdateDialogComponent } from '../@fury/shared/dialog/app-update-dialog/app-update-dialog.component';
import { SnackbarService } from './services/base/snackbar.service';
import { interval, Observable } from 'rxjs';
import Echo from 'laravel-echo';
import { environment } from '../environments/environment';
import { LocalStorageService } from './services/base/local-storage.service';
import { version } from '../environments/version';
import { LayoutService } from './layout/layout.service';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  private swUpdatePending: boolean = false;

  constructor(private sidenavService: SidenavService,
              private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
              private platform: Platform,
              private splashScreenService: SplashScreenService, // required!! otherwise splashScreen does not go away on app load
              private authenticationService: AuthenticationService,
              private swUpdate: SwUpdate,
              private dialogService: MatDialog,
              private snackbarService: SnackbarService,
              private localStorageService: LocalStorageService,
              private layoutService: LayoutService,
  ) {
    this.renderer.addClass(this.document.body, 'fury-default');
    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    if (this.swUpdate.isEnabled) {
      const oncePerHour$: Observable<number> = interval(360 * 1000);

      oncePerHour$.subscribe((): void => {
        console.log('onceAppIsStable$');
        this.swUpdate.checkForUpdate()
          .then((): void => console.log('checkForUpdate()'))
          .catch((error): void => {
            console.log('Service worker not supported or disabled!');
            throw new Error(error);
          });
      });

      this.swUpdate.versionUpdates.subscribe((event: VersionEvent): void => {
        if (!this.swUpdatePending) {
          if (event.type === 'VERSION_DETECTED') {
            this.snackbarService.info('Er is een nieuwe versie van de applicatie beschikbaar die op de achtergrond wordt klaargezet.');
          }
          if (event.type === 'VERSION_READY') {
            console.log('ServiceWorker update available: yes');
            this.swUpdatePending = true;
            let dialogRef: MatDialogRef<AppUpdateDialogComponent>;
            dialogRef = this.dialogService.open(AppUpdateDialogComponent, {
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(response => {
              if (response === true) {
                this.swUpdate.activateUpdate().then((): void => {
                  window.location.reload();
                });
              } else {
                this.layoutService.appUpdatePending = true;
                this.layoutService.updateApp.subscribe((): void => {
                  this.swUpdate.activateUpdate().then((): void => {
                    window.location.reload();
                  });
                });
              }
            });
          }
        }
      });
    }

    this.authenticationService.user$.subscribe((user: User): void => {
      if (user) {
        if (typeof window.Echo === 'undefined') {
          window.Echo = new Echo({
            ...environment.pusher,
            ...{
              authEndpoint: environment.api_endpoint + 'broadcasting/auth',
              auth: {
                headers: {
                  Accept: 'application/json',
                  Authorization: `Bearer ${this.localStorageService.get('api-token')}`
                }
              }
            }
          });
        }

        if (typeof window.Marker !== 'undefined') {
          window.markerConfig.reporter = {
            email: user.email,
            fullName: user.name,
          };

          window.Marker.setCustomData({
            version: version
          });
        }

        this.sidenavService.items = [];
        this.sidenavService.addItems([
          {
            name: 'Meldingen',
            icon: 'assignment',
            position: 10,
            alwaysOpened: true,
            permissions: [
              'reports.unassigned.assign',
              'reports.handle.bulk',
              'reports.copy',
              'reports.create',
              'reports.delete',
              'reports.closed.edit',
              'reports.forward',
              'reports.print',
              'reports.view.all-organisations',
              'reports.closed.reopen',
              'reports.handle',
              'reports.deleted.read',
              'reports.unassigned.view',
              'reports.update',
              'reports.view',
            ],
            subItems: [
              {
                name: 'Alle meldingen',
                routeOrFunction: '/meldingen/alle',
                icon: 'assignment',
                position: 0,
                badgeItem: 'reports_overview',
                permissions: [
                  'reports.view.all',
                ],
              },
              {
                name: 'Mijn meldingen',
                routeOrFunction: '/meldingen',
                icon: 'assignment',
                position: 10,
                badgeItem: 'reports_open',
                permissions: [
                  'reports.unassigned.assign',
                  'reports.handle.bulk',
                  'reports.copy',
                  'reports.create',
                  'reports.delete',
                  'reports.closed.edit',
                  'reports.forward',
                  'reports.print',
                  'reports.view.all-organisations',
                  'reports.closed.reopen',
                  'reports.handle',
                  'reports.deleted.read',
                  'reports.unassigned.view',
                  'reports.update',
                  'reports.view',
                ],
              },
              {
                name: 'Controle',
                routeOrFunction: '/meldingen/controle',
                icon: 'assignment',
                position: 11,
                badgeItem: 'reports_review',
                permissions: [
                  'reports.review',
                ],
              },
              {
                name: 'Gesloten',
                routeOrFunction: '/meldingen/gesloten',
                icon: 'assignment_turned_in',
                position: 15,
                badgeItem: 'reports_closed',
                permissions: [
                  'reports.unassigned.assign',
                  'reports.handle.bulk',
                  'reports.copy',
                  'reports.create',
                  'reports.delete',
                  'reports.closed.edit',
                  'reports.forward',
                  'reports.print',
                  'reports.view.all-organisations',
                  'reports.closed.reopen',
                  'reports.handle',
                  'reports.deleted.read',
                  'reports.unassigned.view',
                  'reports.update',
                  'reports.view',
                ],
              },
            ]
          },
          {
            name: 'Nieuwe melding',
            routeOrFunction: '/meldingen/nieuw',
            icon: 'add_user',
            position: 15,
            alwaysOpened: false,
            permissions: [
              'reports.create'
            ],
          },
          {
            name: 'Kaart weergave',
            routeOrFunction: '/kaart-weergave',
            icon: 'map',
            position: 96,
            alwaysOpened: false,
            permissions: [
              'map.view',
            ],
          },
          {
            name: 'Rapportages',
            routeOrFunction: '/statistics',
            icon: 'insert_chart',
            position: 97,
            alwaysOpened: false,
            permissions: [
              'statistics.read'
            ],
          },
          {
            name: 'Afroeporders',
            icon: 'assignment',
            position: 98,
            alwaysOpened: false,
            permissions: [
              'waste-orders.manage',
            ],
            subItems: [
              {
                name: 'Openstaand',
                routeOrFunction: '/afroeporders',
                position: 10,
                badgeItem: 'waste_orders_open',
                permissions: [
                  'waste-orders.manage',
                ],
              },
              {
                name: 'Opgehaald',
                routeOrFunction: '/afroeporders/opgehaald',
                position: 20,
                badgeItem: 'waste_orders_closed',
                permissions: [
                  'waste-orders.manage',
                ],
              },
              {
                name: 'Alle',
                routeOrFunction: '/afroeporders/alle',
                position: 30,
                badgeItem: 'waste_orders_all',
                permissions: [
                  'waste-orders.manage',
                ],
              },
            ],
          },
          {
            name: 'Nieuwe afroeporder',
            routeOrFunction: '/afroeporders/nieuw',
            icon: 'add_user',
            position: 98,
            alwaysOpened: false,
            permissions: [
              'waste-orders.manage'
            ],
          },
          {
            name: 'BEHEER',
            position: 100,
            type: 'subheading',
            permissions: [
              'routering.organisations.manage',
              'routering.departments.manage',
              'routering.roles.manage',
              'routering.users.manage',
              'routering.categories.manage',
              'routering.organisations.manage',
              'routering.departments.manage',
              'routering.roles.manage',
              'routering.users.manage',
              'routering.categories.manage',
              'configuration.public-notifications.manage',
              'configuration.email-templates.manage',
              'configuration.standard-reactions.manage',
              'configuration.address-book.manage',
              'configuration.knowledge-base.manage',
              'configuration.faq.manage',
              'configuration.changelogs.manage',
              'configuration.report-statuses.manage',
              'configuration.report-sources.manage',
              'configuration.report-priorities.manage',
              'configuration.report-history.manage',
              'archive.manage',
              'waste-calendar.manage',
              'configuration.waste-order-statuses-and-types.manage',
              'configuration.reporters.manage',
            ],
          },
          {
            name: 'Routering',
            icon: 'call_split',
            position: 100,
            alwaysOpened: false,
            permissions: [
              'routering.organisations.manage',
              'routering.departments.manage',
              'routering.roles.manage',
              'routering.users.manage',
              'routering.categories.manage',
            ],
            subItems: [
              {
                name: 'Organisaties',
                routeOrFunction: '/routering/organisaties',
                position: 10,
                permissions: [
                  'routering.organisations.manage'
                ],
              },
              {
                name: 'Afdelingen',
                routeOrFunction: '/routering/afdelingen',
                position: 15,
                permissions: [
                  'routering.departments.manage',
                ],
              },
              {
                name: 'Gebruikertypes',
                routeOrFunction: '/routering/gebruikertypes',
                position: 20,
                permissions: [
                  'routering.roles.manage',
                ],
              },
              {
                name: 'Gebruikers',
                routeOrFunction: '/routering/gebruikers',
                position: 25,
                permissions: [
                  'routering.users.manage',
                ],
              },
              {
                name: 'Hoofdcategorie',
                routeOrFunction: '/routering/categorieen',
                position: 30,
                permissions: [
                  'routering.categories.manage'
                ],
              },
            ]
          },
          {
            name: 'Publieke notificaties',
            icon: 'add_alert',
            position: 140,
            routeOrFunction: '/aankondigingen',
            permissions: [
              'configuration.public-notifications.manage',
            ]
          },
          {
            name: 'Instellingen',
            icon: 'settings',
            position: 150,
            alwaysOpened: false,
            permissions: [
              'configuration.cities.manage',
              'configuration.email-templates.manage',
              'configuration.standard-reactions.manage',
              'configuration.address-book.manage',
              'configuration.knowledge-base.manage',
              'configuration.faq.manage',
              'configuration.changelogs.manage',
              'configuration.report-statuses.manage',
              'configuration.report-sources.manage',
              'configuration.report-priorities.manage',
              'configuration.report-history.manage',
              'configuration.blacklist.manage',
              'configuration.waste-order-statuses-and-types.manage',
            ],
            subItems: [
              {
                name: 'Steden',
                routeOrFunction: '/instellingen/steden',
                position: 0,
                permissions: [
                  'configuration.cities.manage'
                ],
              },
              {
                name: 'E-mail templates',
                routeOrFunction: '/instellingen/email-templates',
                position: 10,
                permissions: [
                  'configuration.email-templates.manage'
                ],
              },
              {
                name: 'Standaard reacties',
                routeOrFunction: '/instellingen/standaard-reacties',
                position: 15,
                permissions: [
                  'configuration.standard-reactions.manage'
                ],
              },
              {
                name: 'Adressenboek',
                routeOrFunction: '/instellingen/adressenboek',
                position: 20,
                permissions: [
                  'configuration.address-book.manage'
                ],
              },
              {
                name: 'Handleiding',
                position: 25,
                alwaysOpened: false,
                permissions: [
                  'configuration.knowledge-base.manage',
                  'configuration.faq.manage',
                  'configuration.changelogs.manage',
                ],
                subItems: [
                  {
                    name: 'Handleiding',
                    routeOrFunction: '/instellingen/handleiding',
                    position: 10,
                    permissions: [
                      'configuration.knowledge-base.manage',
                    ]
                  },
                  {
                    name: 'FAQ',
                    routeOrFunction: '/instellingen/handleiding/faq',
                    position: 15,
                    permissions: [
                      'configuration.faq.manage',
                    ]
                  },
                  {
                    name: 'Contact gegevens',
                    routeOrFunction: '/instellingen/handleiding/contact-gegevens',
                    position: 20,
                    permissions: []
                  },
                  {
                    name: 'Update applicatie',
                    position: 25,
                    routeOrFunction: '/instellingen/handleiding/update-applicatie',
                    permissions: [
                      'configuration.changelogs.manage',
                    ],
                  },
                ],
              },
              {
                name: 'Invoer',
                alwaysOpened: false,
                position: 30,
                permissions: [
                  'configuration.report-statuses.manage',
                  'configuration.report-sources.manage',
                  'configuration.report-priorities.manage',
                  'configuration.report-history.manage',
                ],
                subItems: [
                  {
                    name: 'Statussen',
                    routeOrFunction: '/instellingen/invoer/statussen',
                    position: 15,
                    permissions: [
                      'configuration.report-statuses.manage',
                    ],
                  },
                  {
                    name: 'Wijzen van melden',
                    routeOrFunction: '/instellingen/invoer/wijzen-van-melden',
                    position: 20,
                    permissions: [
                      'configuration.report-sources.manage',
                    ],
                  },
                  {
                    name: 'Prioriteiten',
                    routeOrFunction: '/instellingen/invoer/prioriteiten',
                    position: 21,
                    permissions: [
                      'configuration.report-priorities.manage',
                    ],
                  },
                  {
                    name: 'Geschiedenis',
                    routeOrFunction: '/instellingen/invoer/geschiedenis',
                    position: 25,
                    permissions: [
                      'configuration.report-history.manage',
                    ],
                  },
                ]
              },
              {
                name: 'Publieke notificaties types',
                routeOrFunction: '/aankondigingen/types',
                position: 35,
                permissions: [
                  'configuration.public-notifications.manage'
                ],
              },
              {
                name: 'Update applicaties types',
                routeOrFunction: '/instellingen/handleiding/update-applicatie/types',
                position: 40,
                permissions: [
                  'configuration.changelogs.types.manage',
                ],
              },
              {
                name: 'Feestdagen',
                routeOrFunction: '/instellingen/feestdagen',
                position: 60,
                permissions: [
                  'configuration.holidays.manage',
                ]
              },
              {
                name: 'Blokkadelijst',
                routeOrFunction: '/instellingen/blokkadelijst',
                position: 70,
                permissions: [
                  'configuration.blacklist.manage',
                ],
              },
              {
                name: 'Afroeporders',
                alwaysOpened: false,
                position: 80,
                subItems: [
                  {
                    name: 'Statussen',
                    routeOrFunction: '/instellingen/afroeporders/statussen',
                    position: 0,
                    permissions: [
                      'configuration.waste-order-statuses-and-types.manage'
                    ],
                  },
                  {
                    name: 'Types',
                    routeOrFunction: '/instellingen/afroeporders/types',
                    position: 10,
                    permissions: [
                      'configuration.waste-order-statuses-and-types.manage',
                    ],
                  },
                ],
              },
            ]
          },
          {
            name: 'Melders',
            icon: 'account_circle',
            position: 150,
            routeOrFunction: '/melders',
            alwaysOpened: false,
            permissions: [
              'reporters.manage',
            ],
          },
          {
            name: 'Afvalkalender',
            icon: 'calendar_month',
            position: 160,
            alwaysOpened: false,
            permissions: [
              'waste-calendar.manage',
            ],
            subItems: [
              {
                name: 'Afval data',
                routeOrFunction: '/afvalkalender/afval-data',
                position: 0,
                permissions: [
                  'waste-calendar.manage'
                ],
              },
              {
                name: 'Postcodes',
                routeOrFunction: '/afvalkalender/postcodes',
                position: 10,
                permissions: [
                  'waste-calendar.manage'
                ],
              },
              {
                name: 'Soorten afval',
                routeOrFunction: '/afvalkalender/soorten-afval',
                position: 20,
                permissions: [
                  'waste-calendar.manage'
                ],
              },
              {
                name: 'Test',
                routeOrFunction: '/afvalkalender/test',
                position: 30,
                permissions: [
                  'waste-calendar.manage'
                ],
              },
            ]
          },
          {
            name: 'Archivering',
            routeOrFunction: '/archivering',
            icon: 'archive',
            position: 210,
            alwaysOpened: false,
            permissions: [
              'archive.manage',
            ],
          },
          {
            name: 'Sent Emails',
            routeOrFunction: '/sent-emails',
            icon: 'email',
            position: 220,
            alwaysOpened: false,
            permissions: [],
          },
        ]);

        this.sidenavService.addItemsBottom([
          {
            name: 'Help & support',
            routeOrFunction: '/handleiding',
            icon: 'help',
            position: 10
          },

        ]);
      }
    });
  }
}
