// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols

import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from './api-endpoints.service';
import { AddressBookType } from '../../interfaces/configuration/address-book-type';
import { environment } from '../../../environments/environment';
import { Organisation } from '../../interfaces/routering/organisation';
import { Department } from '../../interfaces/routering/department';
import { User } from '../../interfaces/routering/user';
import { City } from '../../interfaces/configuration/city';
import { Role } from '../../interfaces/routering/role';
import { MailType } from '../../interfaces/configuration/mail-type';
import { ReportHistoryType } from '../../interfaces/configuration/report-history-type';
import { ReportSource } from '../../interfaces/configuration/report-source';
import { ReportStatus } from '../../interfaces/configuration/report-status';
import { HeadCategory } from '../../interfaces/routering/head-category';
import { SubCategory } from '../../interfaces/routering/sub-category';
import { AddressBook } from '../../interfaces/configuration/address-book';
import { StandardReaction } from '../../interfaces/configuration/standard-reaction';
import { IntegratedOptions } from '../../interfaces/base/integrated-options';
import { ChangelogType } from '../../interfaces/configuration/changelog-type';
import { PublicNotificationType } from '../../interfaces/configuration/public-notification-type';
import { ContentVisibility } from '../../interfaces/configuration/content-visibility';
import { Report } from '../../interfaces/report/report';
import { Permission } from '../../interfaces/routering/permission';
import { MailTag } from '../../interfaces/sent-emails/mail-tag';
import { MailEvent } from '../../interfaces/sent-emails/mail-event';
import { MailLogLevel } from '../../interfaces/sent-emails/mail-log-level';
import { FilterData, Filters } from '../../interfaces/base/filters';
import { ReportPriority } from '../../interfaces/report/report-priority';
import { DomainType } from '../../interfaces/configuration/domain-type';
import { BlacklistType } from '../../interfaces/configuration/blacklist-type';
import { WasteOrderStatus } from '../../interfaces/configuration/waste-order-status';
import { WasteOrderType } from '../../interfaces/configuration/waste-order-type';
import { WasteType } from '../../interfaces/waste-calendar/waste-type';
import { WasteImportSerie } from '../../interfaces/waste-calendar/waste-import-serie';
import { WasteRouteGroup } from '../../interfaces/waste-calendar/waste-route-group';
import { Month } from '../../interfaces/base/month';
import { DepartmentAndUser } from '../../interfaces/routering/department-and-user';

@Injectable()

export class FiltersService {

  public filters: Filters = null;

  private filterData: FilterData = {
    addressBook: [],
    blacklistTypes: [],
    changelogTypes: [],
    departments: [],
    departmentsAndUsers: [],
    domainTypes: [],
    headCategories: [],
    historyEntries: [],
    historyTypes: [],
    organisations: [],
    reportPriorities: [],
    reportSources: [],
    reportStatuses: [],
    standardReactions: [],
    subCategories: [],
    users: [],
    publicNotificationTypes: [],
    contentVisibility: [],
    archiveUsers: [],
    archiveUsersAll: [],
    mailEvents: [],
    mailLogLevels: [],
    mailSubjects: [],
    mailTags: [],
    wasteOrderStatuses: [],
    wasteOrderTypes: [],
    wasteDataRouteGroups: [],
    wastePostalcodeRouteGroups: [],
  };

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  activeStatuses(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Alle',
          id: null
        },
        {
          name: 'Ja',
          id: true
        },
        {
          name: 'Nee',
          id: false
        }
      ]);
    });
  }

  yesNo(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Ja',
          id: true
        },
        {
          name: 'Nee',
          id: false
        }
      ]);
    });
  }

  yesNoUnknown(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Ja',
          id: 'Ja'
        },
        {
          name: 'Nee',
          id: 'Nee'
        },
        {
          name: 'Onbekend',
          id: 'Onbekend'
        }
      ]);
    });
  }

  personPrefix(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Heer',
          id: 'Heer'
        },
        {
          name: 'Mevrouw',
          id: 'Mevrouw'
        },
        {
          name: 'Neutraal',
          id: 'Neutraal',
        }
      ]);
    });
  }

  reportAssignToTypes(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Geen',
          id: null,
        },
        {
          name: 'Gebruiker',
          id: 'users'
        },
        {
          name: 'Afdelingen',
          id: 'departments'
        },
      ]);
    });
  }

  archiveCollectionStatus(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          id: 'created',
          name: 'Aangemaakt'
        },
        {
          id: 'in-review',
          name: 'Beoordelen'
        },
        {
          id: 'rejected',
          name: 'Afgewezen'
        },
        {
          id: 'approved',
          name: 'Geaccordeerd'
        },
        {
          id: 'destroyed',
          name: 'Vernietigd'
        },
      ]);
    });
  }

  for(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Alle',
          id: null
        },
        {
          name: 'Voltooien',
          id: 'resolved'
        },
        {
          name: 'Afwijzen',
          id: 'rejected'
        },
      ]);
    });
  }

  months(): Observable<Month[]> {
    return new Observable<Month[]>((o: Subscriber<Month[]>): void => {
      o.next([
        {
          name: 'Januari',
          short_name: 'Jan',
          id: 1
        },
        {
          name: 'Februari',
          short_name: 'Feb',
          id: 2
        },
        {
          name: 'Maart',
          short_name: 'Maa',
          id: 3
        },
        {
          name: 'April',
          short_name: 'Apr',
          id: 4
        },
        {
          name: 'Mei',
          short_name: 'Mei',
          id: 5
        },
        {
          name: 'Juni',
          short_name: 'Jun',
          id: 6
        },
        {
          name: 'Juli',
          short_name: 'Jul',
          id: 7
        },
        {
          name: 'Augustus',
          short_name: 'Aug',
          id: 8
        },
        {
          name: 'September',
          short_name: 'Sep',
          id: 9
        },
        {
          name: 'Oktober',
          short_name: 'Okt',
          id: 10
        },
        {
          name: 'November',
          short_name: 'Nov',
          id: 11
        },
        {
          name: 'December',
          short_name: 'Dec',
          id: 12
        },
      ]);
    });
  }

  organisations(): Observable<Organisation[]> {
    return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.organisations.length) {
        o.next(this.filterData.organisations);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisations !== 'undefined') {
            this.filters.organisations.isLoading = true;
          }
        }
        subscription = this.getOrganisations().subscribe((data: Organisation[]): void => {
          this.filterData.organisations = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisations !== 'undefined') {
              this.filters.organisations.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        if (!environment.production) {
          console.log('WebSocket: subscribed to event for "filters" channel: ' + '.organisations.changed');
        }
        window.Echo.private('filters').listen('.organisations.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.organisations.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.organisations !== 'undefined') {
              this.filters.organisations.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getOrganisations().subscribe((data: Organisation[]): void => {
            o.next(data);
            this.filterData.organisations = data;
            if (this.filters !== null) {
              if (typeof this.filters.organisations !== 'undefined') {
                this.filters.organisations.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getOrganisations(): Observable<Organisation[]> {
    return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisations'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });
      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationsForActiveUser(): Observable<Organisation[]> {
    return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
      let subscription: Subscription = null;

      // get data from server
      if (this.filters !== null) {
        if (typeof this.filters.organisationsForActiveUser !== 'undefined') {
          this.filters.organisationsForActiveUser.isLoading = true;
        }
      }
      subscription = this.api.get(this.apiEndpoints.get('filters.organisations-for-active-user'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (this.filters !== null) {
            if (typeof this.filters.organisationsForActiveUser !== 'undefined') {
              this.filters.organisationsForActiveUser.isLoading = false;
            }
          }

          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          if (this.filters !== null) {
            if (typeof this.filters.organisationsForActiveUser !== 'undefined') {
              this.filters.organisationsForActiveUser.isLoading = false;
            }
          }
          o.next([]);
        });

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  departments(event: 'start' | 'stop' = 'start'): Observable<Department[]> {
    if (event === 'start') {
      return new Observable<Department[]>((o: Subscriber<Department[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.departments.length) {
          o.next(this.filterData.departments);
        } else {
          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.departments !== 'undefined') {
              this.filters.departments.isLoading = true;
            }
          }
          subscription = this.getDepartments().subscribe((data: Department[]): void => {
            o.next(data);
            this.filterData.departments = data;
            if (this.filters !== null) {
              if (typeof this.filters.departments !== 'undefined') {
                this.filters.departments.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.departments.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.departments.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.departments !== 'undefined') {
                this.filters.departments.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getDepartments().subscribe((data: Department[]): void => {
              o.next(data);
              this.filterData.departments = data;
              if (this.filters !== null) {
                if (typeof this.filters.departments !== 'undefined') {
                  this.filters.departments.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.departments.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.departments.changed');
      }
    }
  }

  getDepartments(): Observable<Department[]> {
    return new Observable<Department[]>((o: Subscriber<Department[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.departments'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  departmentsAndUsers(event: 'start' | 'stop' = 'start'): Observable<DepartmentAndUser[]> {
    if (event === 'start') {
      return new Observable<DepartmentAndUser[]>((o: Subscriber<DepartmentAndUser[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.departmentsAndUsers.length) {
          o.next(this.filterData.departmentsAndUsers);
        } else {
          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.departmentsAndUsers !== 'undefined') {
              this.filters.departmentsAndUsers.isLoading = true;
            }
          }
          subscription = this.getDepartmentsAndUsers().subscribe((data: DepartmentAndUser[]): void => {
            o.next(data);
            this.filterData.departmentsAndUsers = data;
            if (this.filters !== null) {
              if (typeof this.filters.departmentsAndUsers !== 'undefined') {
                this.filters.departmentsAndUsers.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters')
            .listen('.departments.changed', () => {
              if (!environment.production) {
                console.log('WebSocket: received event for "filters" channel: ' + '.departments.changed');
              }

              if (this.filters !== null) {
                if (typeof this.filters.departmentsAndUsers !== 'undefined') {
                  this.filters.departmentsAndUsers.isLoading = true;
                }
              }
              const subscription2: Subscription = this.getDepartmentsAndUsers().subscribe((data: DepartmentAndUser[]): void => {
                o.next(data);
                this.filterData.departmentsAndUsers = data;
                if (this.filters !== null) {
                  if (typeof this.filters.departmentsAndUsers !== 'undefined') {
                    this.filters.departmentsAndUsers.isLoading = false;
                  }
                }
              });

              return (): void => {
                subscription2.unsubscribe();
              };
            })
            .listen('.users.changed', () => {
              if (!environment.production) {
                console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
              }

              if (this.filters !== null) {
                if (typeof this.filters.departmentsAndUsers !== 'undefined') {
                  this.filters.departmentsAndUsers.isLoading = true;
                }
              }
              const subscription2: Subscription = this.getDepartmentsAndUsers().subscribe((data: DepartmentAndUser[]): void => {
                o.next(data);
                this.filterData.departmentsAndUsers = data;
                if (this.filters !== null) {
                  if (typeof this.filters.departmentsAndUsers !== 'undefined') {
                    this.filters.departmentsAndUsers.isLoading = false;
                  }
                }
              });

              return (): void => {
                subscription2.unsubscribe();
              };
            });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.departments.changed');
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.users.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters')
          .stopListening('.departments.changed')
          .stopListening('.users.changed');
      }
    }
  }

  getDepartmentsAndUsers(): Observable<DepartmentAndUser[]> {
    return new Observable<DepartmentAndUser[]>((o: Subscriber<DepartmentAndUser[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.departments-and-users'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationsWithDepartments(event: 'start' | 'stop' = 'start'): Observable<Organisation[]> {
    if (event === 'start') {
      return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisationsWithDepartments !== 'undefined') {
            this.filters.organisationsWithDepartments.isLoading = true;
          }
        }
        subscription = this.getOrganisationsWithDepartments().subscribe((data: Organisation[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisationsWithDepartments !== 'undefined') {
              this.filters.organisationsWithDepartments.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          if (!environment.production) {
            console.log('WebSocket: subscribed to event for "filters" channel: ' + '.organisations.changed');
          }
          window.Echo.private('filters').listen('.organisations.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.organisations.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.organisationsWithDepartments !== 'undefined') {
                this.filters.organisationsWithDepartments.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getOrganisationsWithDepartments().subscribe((data: Organisation[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.organisationsWithDepartments !== 'undefined') {
                  this.filters.organisationsWithDepartments.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.organisations.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.organisations.changed');
      }
    }
  }

  getOrganisationsWithDepartments(): Observable<Organisation[]> {
    return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisations-with-departments'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationDepartments(organisation_id: number, event: 'start' | 'stop' = 'start'): Observable<Department[]> {
    if (event === 'start') {
      return new Observable<Department[]>((o: Subscriber<Department[]>) => {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisationDepartments !== 'undefined') {
            this.filters.organisationDepartments.isLoading = true;
          }
        }

        const subscription: Subscription = this.getOrganisationDepartments(organisation_id).subscribe((data: Department[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisationDepartments !== 'undefined') {
              this.filters.organisationDepartments.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.departments.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.departments.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.organisationDepartments !== 'undefined') {
                this.filters.organisationDepartments.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getOrganisationDepartments(organisation_id).subscribe((data: Department[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.organisationDepartments !== 'undefined') {
                  this.filters.organisationDepartments.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        return (): void => {
          subscription.unsubscribe();
        };
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.departments.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.departments.changed');
      }
    }
  }

  getOrganisationDepartments(organisation_id: number): Observable<Department[]> {
    return new Observable<Department[]>((o: Subscriber<Department[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisation-departments', {':id': organisation_id}), null, true).subscribe(
        (response: ServerResponse) => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        () => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationUsers(organisation_id: number, event: 'start' | 'stop' = 'start'): Observable<User[]> {
    if (event === 'start') {
      return new Observable<User[]>((o: Subscriber<User[]>) => {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisationUsers !== 'undefined') {
            this.filters.organisationUsers.isLoading = true;
          }
        }

        const subscription: Subscription = this.getOrganisationUsers(organisation_id).subscribe((data: User[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisationUsers !== 'undefined') {
              this.filters.organisationUsers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.users.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.organisationUsers !== 'undefined') {
                this.filters.organisationUsers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getOrganisationUsers(organisation_id).subscribe((data: User[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.organisationUsers !== 'undefined') {
                  this.filters.organisationUsers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        return (): void => {
          subscription.unsubscribe();
        };
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.users.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.users.changed');
      }
    }
  }

  getOrganisationUsers(organisation_id: number): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisation-users', {':id': organisation_id}), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationDepartmentUsers(organisation_id: number, department_id: number, event: 'start' | 'stop' = 'start'): Observable<User[]> {
    if (event === 'start') {
      return new Observable<User[]>((o: Subscriber<User[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisationDepartmentUsers !== 'undefined') {
            this.filters.organisationDepartmentUsers.isLoading = true;
          }
        }
        subscription = this.getOrganisationDepartmentUsers(organisation_id, department_id).subscribe((data: User[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisationDepartmentUsers !== 'undefined') {
              this.filters.organisationDepartmentUsers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.users.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.organisationDepartmentUsers !== 'undefined') {
                this.filters.organisationDepartmentUsers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getOrganisationDepartmentUsers(organisation_id, department_id).subscribe((data: User[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.organisationDepartmentUsers !== 'undefined') {
                  this.filters.organisationDepartmentUsers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.users.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.users.changed');
      }
    }
  }

  getOrganisationDepartmentUsers(organisation_id: number, department_id: number): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisation-department-users', {
        ':organisation_id': organisation_id,
        ':department_id': department_id
      }), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  assignedOrganisationDepartments(event: 'start' | 'stop' = 'start'): Observable<Department[]> {
    if (event === 'start') {
      return new Observable<Department[]>((o: Subscriber<Department[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.assignedOrganisationDepartments !== 'undefined') {
            this.filters.assignedOrganisationDepartments.isLoading = true;
          }
        }
        subscription = this.getAssignedOrganisationDepartments().subscribe((data: Department[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.assignedOrganisationDepartments !== 'undefined') {
              this.filters.assignedOrganisationDepartments.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.departments.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.departments.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.assignedOrganisationDepartments !== 'undefined') {
                this.filters.assignedOrganisationDepartments.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAssignedOrganisationDepartments().subscribe((data: Department[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.assignedOrganisationDepartments !== 'undefined') {
                  this.filters.assignedOrganisationDepartments.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.departments.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.departments.changed');
      }
    }
  }

  getAssignedOrganisationDepartments(): Observable<Department[]> {
    return new Observable<Department[]>((o: Subscriber<Department[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.assigned-organisation-departments'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  assignedOrganisationUsers(event: 'start' | 'stop' = 'start'): Observable<User[]> {
    if (event === 'start') {
      return new Observable<User[]>((o: Subscriber<User[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.assignedOrganisationUsers !== 'undefined') {
            this.filters.assignedOrganisationUsers.isLoading = true;
          }
        }
        subscription = this.getAssignedOrganisationUsers().subscribe((data: User[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.assignedOrganisationUsers !== 'undefined') {
              this.filters.assignedOrganisationUsers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.users.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.assignedOrganisationUsers !== 'undefined') {
                this.filters.assignedOrganisationUsers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAssignedOrganisationUsers().subscribe((data: User[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.assignedOrganisationUsers !== 'undefined') {
                  this.filters.assignedOrganisationUsers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.users.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.users.changed');
      }
    }
  }

  getAssignedOrganisationUsers(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.assigned-organisation-users'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  departmentUsers(department_id: number, event: 'start' | 'stop' = 'start'): Observable<User[]> {
    if (event === 'start') {
      return new Observable<User[]>((o: Subscriber<User[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.departmentUsers !== 'undefined') {
            this.filters.departmentUsers.isLoading = true;
          }
        }
        subscription = this.getDepartmentUsers(department_id).subscribe((data: User[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.departmentUsers !== 'undefined') {
              this.filters.departmentUsers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.users.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.departmentUsers !== 'undefined') {
                this.filters.departmentUsers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getDepartmentUsers(department_id).subscribe((data: User[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.departmentUsers !== 'undefined') {
                  this.filters.departmentUsers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.users.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.users.changed');
      }
    }
  }

  getDepartmentUsers(department_id: number): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.assigned-organisation-department-users', {':department_id': department_id}), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  cities(event: 'start' | 'stop' = 'start'): Observable<City[]> {
    if (event === 'start') {
      return new Observable<City[]>((o: Subscriber<City[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.cities !== 'undefined') {
            this.filters.cities.isLoading = true;
          }
        }
        subscription = this.getCities().subscribe((data: City[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.cities !== 'undefined') {
              this.filters.cities.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.cities.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.cities.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.cities !== 'undefined') {
                this.filters.cities.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getCities().subscribe((data: City[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.cities !== 'undefined') {
                  this.filters.cities.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.cities.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.cities.changed');
      }
    }
  }

  getCities(): Observable<City[]> {
    return new Observable<City[]>((o: Subscriber<City[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.cities'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  city(city_id: number, event: 'start' | 'stop' = 'start'): Observable<City> {
    if (event === 'start') {
      return new Observable<City>((o: Subscriber<City>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.city !== 'undefined') {
            this.filters.city.isLoading = true;
          }
        }
        subscription = this.getCity(city_id).subscribe((data: City): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.city !== 'undefined') {
              this.filters.city.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.cities.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.cities.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.city !== 'undefined') {
                this.filters.city.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getCity(city_id).subscribe((data: City): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.city !== 'undefined') {
                  this.filters.city.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.cities.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.cities.changed');
      }
    }
  }

  getCity(city_id: number): Observable<City> {
    return new Observable<City>((o: Subscriber<City>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.city', {':city_id': city_id}), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next(null);
          }
        },
        (): void => {
          o.next(null);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  permissions(event: 'start' | 'stop' = 'start'): Observable<Permission[]> {
    if (event === 'start') {
      return new Observable<Permission[]>((o: Subscriber<Permission[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.permissions !== 'undefined') {
            this.filters.permissions.isLoading = true;
          }
        }
        subscription = this.getPermissions().subscribe((data: Permission[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.permissions !== 'undefined') {
              this.filters.permissions.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.permissions.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.permissions.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.permissions !== 'undefined') {
                this.filters.permissions.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getPermissions().subscribe((data: Permission[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.permissions !== 'undefined') {
                  this.filters.permissions.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.permissions.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.permissions.changed');
      }
    }
  }

  getPermissions(): Observable<Permission[]> {
    return new Observable<Permission[]>((o: Subscriber<Permission[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.permissions'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  permissionGroups(event: 'start' | 'stop' = 'start'): Observable<Permission[]> {
    if (event === 'start') {
      return new Observable<Permission[]>((o: Subscriber<Permission[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.permissionGroups !== 'undefined') {
            this.filters.permissionGroups.isLoading = true;
          }
        }
        subscription = this.getPermissionGroups().subscribe((data: Permission[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.permissionGroups !== 'undefined') {
              this.filters.permissionGroups.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.permission-groups.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.permission-groups.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.permissionGroups !== 'undefined') {
                this.filters.permissionGroups.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getPermissionGroups().subscribe((data: Permission[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.permissionGroups !== 'undefined') {
                  this.filters.permissionGroups.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.permission-groups.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.permission-groups.changed');
      }
    }
  }

  getPermissionGroups(): Observable<Permission[]> {
    return new Observable<Permission[]>((o: Subscriber<Permission[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.permission-groups'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next(null);
          }
        },
        (): void => {
          o.next(null);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  roles(event: 'start' | 'stop' = 'start'): Observable<Role[]> {
    if (event === 'start') {
      return new Observable<Role[]>((o: Subscriber<Role[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.roles !== 'undefined') {
            this.filters.roles.isLoading = true;
          }
        }
        subscription = this.getRoles().subscribe((data: Role[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.roles !== 'undefined') {
              this.filters.roles.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.roles.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.roles.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.roles !== 'undefined') {
                this.filters.roles.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getRoles().subscribe((data: Role[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.roles !== 'undefined') {
                  this.filters.roles.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.roles.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.roles.changed');
      }
    }
  }

  getRoles(): Observable<Role[]> {
    return new Observable<Role[]>((o: Subscriber<Role[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.roles'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  users(event: 'start' | 'stop' = 'start'): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.users.length) {
        o.next(this.filterData.users);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.users !== 'undefined') {
            this.filters.users.isLoading = true;
          }
        }
        subscription = this.getUsers().subscribe((data: User[]): void => {
          this.filterData.users = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.users !== 'undefined') {
              this.filters.users.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.users.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.users !== 'undefined') {
              this.filters.users.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getUsers().subscribe((data: User[]): void => {
            this.filterData.users = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.users !== 'undefined') {
                this.filters.users.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getUsers(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.users'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  archiveUsers(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.archiveUsers.length) {
        o.next(this.filterData.archiveUsers);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.archiveUsers !== 'undefined') {
            this.filters.archiveUsers.isLoading = true;
          }
        }
        subscription = this.getArchiveUsers().subscribe((data: User[]): void => {
          this.filterData.archiveUsers = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.archiveUsers !== 'undefined') {
              this.filters.archiveUsers.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getArchiveUsers(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('archive.archivering.users'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  archiveUsersAll(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.archiveUsersAll.length) {
        o.next(this.filterData.archiveUsersAll);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.archiveUsersAll !== 'undefined') {
            this.filters.archiveUsersAll.isLoading = true;
          }
        }
        subscription = this.getArchiveUsersAll().subscribe((data: User[]): void => {
          this.filterData.archiveUsersAll = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.archiveUsersAll !== 'undefined') {
              this.filters.archiveUsersAll.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getArchiveUsersAll(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('archive.archivering.users.all'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  mailTypes(event: 'start' | 'stop' = 'start'): Observable<MailType[]> {
    if (event === 'start') {
      return new Observable<MailType[]>((o: Subscriber<MailType[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.mailTypes !== 'undefined') {
            this.filters.mailTypes.isLoading = true;
          }
        }
        subscription = this.getMailTypes().subscribe((data: MailType[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.mailTypes !== 'undefined') {
              this.filters.mailTypes.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.mail-types.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.mail-types.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.mailTypes !== 'undefined') {
                this.filters.mailTypes.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getMailTypes().subscribe((data: MailType[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.mailTypes !== 'undefined') {
                  this.filters.mailTypes.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.mail-types.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.mail-types.changed');
      }
    }
  }

  getMailTypes(): Observable<MailType[]> {
    return new Observable<MailType[]>((o: Subscriber<MailType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.mail-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  historyTypes(event: 'start' | 'stop' = 'start'): Observable<ReportHistoryType[]> {
    return new Observable<ReportHistoryType[]>((o: Subscriber<ReportHistoryType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.historyTypes.length) {
        o.next(this.filterData.historyTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.historyTypes !== 'undefined') {
            this.filters.historyTypes.isLoading = true;
          }
        }
        subscription = this.getHistoryTypes().subscribe((data: ReportHistoryType[]): void => {
          this.filterData.historyTypes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.historyTypes !== 'undefined') {
              this.filters.historyTypes.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-history-types.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-history-types.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.historyTypes !== 'undefined') {
              this.filters.historyTypes.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getHistoryTypes().subscribe((data: ReportHistoryType[]): void => {
            this.filterData.historyTypes = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.historyTypes !== 'undefined') {
                this.filters.historyTypes.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getHistoryTypes(): Observable<ReportHistoryType[]> {
    return new Observable<ReportHistoryType[]>((o: Subscriber<ReportHistoryType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-history-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportPriorities(): Observable<ReportPriority[]> {
    return new Observable<ReportPriority[]>((o: Subscriber<ReportPriority[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reportPriorities.length) {
        o.next(this.filterData.reportPriorities);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportPriorities !== 'undefined') {
            this.filters.reportPriorities.isLoading = true;
          }
        }
        subscription = this.getReportPriorities().subscribe((data: ReportPriority[]): void => {
          this.filterData.reportPriorities = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportPriorities !== 'undefined') {
              this.filters.reportPriorities.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-priorities.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-priorities.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reportPriorities !== 'undefined') {
              this.filters.reportPriorities.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReportPriorities().subscribe((data: ReportPriority[]): void => {
            this.filterData.reportPriorities = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reportPriorities !== 'undefined') {
                this.filters.reportPriorities.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReportPriorities(): Observable<ReportPriority[]> {
    return new Observable<ReportPriority[]>((o: Subscriber<ReportPriority[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-priorities'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  domainTypes(): Observable<DomainType[]> {
    return new Observable<DomainType[]>((o: Subscriber<DomainType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.domainTypes.length) {
        o.next(this.filterData.domainTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.domainTypes !== 'undefined') {
            this.filters.domainTypes.isLoading = true;
          }
        }
        subscription = this.getDomainTypes().subscribe((data: DomainType[]): void => {
          this.filterData.domainTypes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.domainTypes !== 'undefined') {
              this.filters.domainTypes.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getDomainTypes(): Observable<DomainType[]> {
    return new Observable<DomainType[]>((o: Subscriber<DomainType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.domain-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportSources(event: 'start' | 'stop' = 'start'): Observable<ReportSource[]> {
    return new Observable<ReportSource[]>((o: Subscriber<ReportSource[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reportSources.length) {
        o.next(this.filterData.reportSources);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportSources !== 'undefined') {
            this.filters.reportSources.isLoading = true;
          }
        }
        subscription = this.getReportSources().subscribe((data: ReportSource[]): void => {
          this.filterData.reportSources = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportSources !== 'undefined') {
              this.filters.reportSources.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-sources.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-sources.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reportSources !== 'undefined') {
              this.filters.reportSources.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReportSources().subscribe((data: ReportSource[]): void => {
            this.filterData.reportSources = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reportSources !== 'undefined') {
                this.filters.reportSources.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReportSources(): Observable<ReportSource[]> {
    return new Observable<ReportSource[]>((o: Subscriber<ReportSource[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-sources'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportStatuses(event: 'start' | 'stop' = 'start'): Observable<ReportStatus[]> {
    return new Observable<ReportStatus[]>((o: Subscriber<ReportStatus[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reportStatuses.length) {
        o.next(this.filterData.reportStatuses);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportStatuses !== 'undefined') {
            this.filters.reportStatuses.isLoading = true;
          }
        }
        subscription = this.getReportStatuses().subscribe((data: ReportStatus[]): void => {
          this.filterData.reportStatuses = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportStatuses !== 'undefined') {
              this.filters.reportStatuses.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-statuses.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-statuses.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reportStatuses !== 'undefined') {
              this.filters.reportStatuses.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReportStatuses().subscribe((data: ReportStatus[]): void => {
            this.filterData.reportStatuses = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reportStatuses !== 'undefined') {
                this.filters.reportStatuses.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReportStatuses(): Observable<ReportStatus[]> {
    return new Observable<ReportStatus[]>((o: Subscriber<ReportStatus[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-statuses'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  headCategories(event: 'start' | 'stop' = 'start'): Observable<HeadCategory[]> {
    if (event === 'start') {
      return new Observable<HeadCategory[]>((o: Subscriber<HeadCategory[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (typeof this.filterData.headCategories !== 'undefined' && this.filterData.headCategories.length) {
          o.next(this.filterData.headCategories);
        } else {
          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.headCategories !== 'undefined') {
              this.filters.headCategories.isLoading = true;
            }
          }
          subscription = this.getHeadCategories().subscribe((data: HeadCategory[]): void => {
            this.filterData.headCategories = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.headCategories !== 'undefined') {
                this.filters.headCategories.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.head-categories.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.head-categories.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.headCategories !== 'undefined') {
                this.filters.headCategories.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getHeadCategories().subscribe((data: HeadCategory[]): void => {
              this.filterData.headCategories = data;
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.headCategories !== 'undefined') {
                  this.filters.headCategories.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.head-categories.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.head-categories.changed');
      }
    }
  }

  getHeadCategories(): Observable<HeadCategory[]> {
    return new Observable<HeadCategory[]>((o: Subscriber<HeadCategory[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.head-categories')).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  subCategories(head_category_id: number = null, event: 'start' | 'stop' = 'start'): Observable<SubCategory[]> {
    if (event === 'start') {
      return new Observable<SubCategory[]>((o: Subscriber<SubCategory[]>) => {
        let subscription: Subscription = null;

        if (head_category_id === null && typeof this.filterData.subCategories['null'] !== 'undefined' && this.filterData.subCategories['null'].length ||
          head_category_id !== null && typeof this.filterData.subCategories[head_category_id] !== 'undefined' && this.filterData.subCategories[head_category_id].length) {
          if (head_category_id !== null) {
            o.next(this.filterData.subCategories[head_category_id]);
          } else {
            o.next(this.filterData.subCategories['null']);
          }
        } else {
          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.subCategories !== 'undefined') {
              this.filters.subCategories.isLoading = true;
            }
          }

          subscription = this.getSubCategories(head_category_id).subscribe((data: SubCategory[]): void => {
            if (head_category_id !== null) {
              this.filterData.subCategories[head_category_id] = data;
            } else {
              this.filterData.subCategories['null'] = data;
            }
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.subCategories !== 'undefined') {
                this.filters.subCategories.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.sub-categories.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.sub-categories.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.subCategories !== 'undefined') {
                this.filters.subCategories.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getSubCategories(head_category_id).subscribe((data: SubCategory[]): void => {
              if (head_category_id !== null) {
                this.filterData.subCategories[head_category_id] = data;
              } else {
                this.filterData.subCategories['null'] = data;
              }
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.subCategories !== 'undefined') {
                  this.filters.subCategories.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.sub-categories.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.sub-categories.changed');
      }
    }
  }

  getSubCategories(head_category_id: number): Observable<SubCategory[]> {
    return new Observable<SubCategory[]>((o: Subscriber<SubCategory[]>) => {
      const subscription: Subscription = this.api.get((
        head_category_id
          ? this.apiEndpoints.get('filters.head-categories.sub-categories', {':head_category_id': head_category_id})
          : this.apiEndpoints.get('filters.sub-categories')
      ), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  addressBook(event: 'start' | 'stop' = 'start'): Observable<AddressBook[]> {
    if (event === 'start') {
      return new Observable<AddressBook[]>((o: Subscriber<AddressBook[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.addressBook.length) {
          o.next(this.filterData.addressBook);
        } else {
          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.addressBook !== 'undefined') {
              this.filters.addressBook.isLoading = true;
            }
          }
          subscription = this.getAddressBooks().subscribe((data: AddressBook[]): void => {
            o.next(data);
            this.filterData.addressBook = data;
            if (this.filters !== null) {
              if (typeof this.filters.addressBook !== 'undefined') {
                this.filters.addressBook.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.address-books.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.address-books.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.addressBook !== 'undefined') {
                this.filters.addressBook.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAddressBooks().subscribe((data: AddressBook[]): void => {
              o.next(data);
              this.filterData.addressBook = data;
              if (this.filters !== null) {
                if (typeof this.filters.addressBook !== 'undefined') {
                  this.filters.addressBook.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.address-books.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.address-books.changed');
      }
    }
  }

  getAddressBooks(): Observable<AddressBook[]> {
    return new Observable<AddressBook[]>((o: Subscriber<AddressBook[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.address-book'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  addressBookTypes(event: 'start' | 'stop' = 'start'): Observable<AddressBookType[]> {
    if (event === 'start') {
      return new Observable<AddressBookType[]>((o: Subscriber<AddressBookType[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.addressBookTypes !== 'undefined') {
            this.filters.addressBookTypes.isLoading = true;
          }
        }
        subscription = this.getAddressBookTypes().subscribe((data: AddressBookType[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.addressBookTypes !== 'undefined') {
              this.filters.addressBookTypes.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.address-book-types.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.address-book-types.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.addressBookTypes !== 'undefined') {
                this.filters.addressBookTypes.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAddressBookTypes().subscribe((data: AddressBookType[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.addressBookTypes !== 'undefined') {
                  this.filters.addressBookTypes.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.address-book-types.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.address-book-types.changed');
      }
    }
  }

  getAddressBookTypes(): Observable<AddressBookType[]> {
    return new Observable<AddressBookType[]>((o: Subscriber<AddressBookType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.address-book-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  standardReactions(event: 'start' | 'stop' = 'start'): Observable<StandardReaction[]> {
    if (event === 'start') {
      return new Observable<StandardReaction[]>((o: Subscriber<StandardReaction[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.standardReactions.length) {
          o.next(this.filterData.standardReactions);
        } else {

          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.standardReactions !== 'undefined') {
              this.filters.standardReactions.isLoading = true;
            }
          }
          subscription = this.getStandardReactions().subscribe((data: StandardReaction[]): void => {
            this.filterData.standardReactions = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.standardReactions !== 'undefined') {
                this.filters.standardReactions.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.standard-reactions.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.standard-reactions.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.standardReactions !== 'undefined') {
                this.filters.standardReactions.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getStandardReactions().subscribe((data: StandardReaction[]): void => {
              this.filterData.standardReactions = data;
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.standardReactions !== 'undefined') {
                  this.filters.standardReactions.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.standard-reactions.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.standard-reactions.changed');
      }
    }
  }

  getStandardReactions(): Observable<StandardReaction[]> {
    return new Observable<StandardReaction[]>((o: Subscriber<StandardReaction[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.standard-reactions'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  historyEntries(event: 'start' | 'stop' = 'start'): Observable<ReportHistoryType[]> {
    return new Observable<ReportHistoryType[]>((o: Subscriber<ReportHistoryType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.historyEntries.length) {
        o.next(this.filterData.historyEntries);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.historyEntries !== 'undefined') {
            this.filters.historyEntries.isLoading = true;
          }
        }
        subscription = this.getHistoryEntries().subscribe((data: ReportHistoryType[]): void => {
          this.filterData.historyEntries = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.historyEntries !== 'undefined') {
              this.filters.historyEntries.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-history-entries.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-history-entries.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.historyEntries !== 'undefined') {
              this.filters.historyEntries.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getHistoryEntries().subscribe((data: ReportHistoryType[]): void => {
            this.filterData.historyEntries = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.historyEntries !== 'undefined') {
                this.filters.historyEntries.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getHistoryEntries(): Observable<ReportHistoryType[]> {
    return new Observable<ReportHistoryType[]>((o: Subscriber<ReportHistoryType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-history'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  publicNotificationTypes(event: 'start' | 'stop' = 'start'): Observable<PublicNotificationType[]> {
    if (event === 'start') {
      return new Observable<PublicNotificationType[]>((o: Subscriber<PublicNotificationType[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.publicNotificationTypes !== 'undefined') {
            this.filters.publicNotificationTypes.isLoading = true;
          }
        }
        subscription = this.getPublicNotificationTypes().subscribe((data: PublicNotificationType[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.publicNotificationTypes !== 'undefined') {
              this.filters.publicNotificationTypes.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.public-notification-types.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.public-notification-types.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.publicNotificationTypes !== 'undefined') {
                this.filters.publicNotificationTypes.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getPublicNotificationTypes().subscribe((data: PublicNotificationType[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.publicNotificationTypes !== 'undefined') {
                  this.filters.publicNotificationTypes.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.public-notification-types.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.public-notification-types.changed');
      }
    }
  }

  getPublicNotificationTypes(): Observable<PublicNotificationType[]> {
    return new Observable<PublicNotificationType[]>((o: Subscriber<PublicNotificationType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.public-notification-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  wasteOrderStatuses(): Observable<WasteOrderStatus[]> {
    return new Observable<WasteOrderStatus[]>((o: Subscriber<WasteOrderStatus[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.wasteOrderStatuses.length) {
        o.next(this.filterData.wasteOrderStatuses);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.wasteOrderStatuses !== 'undefined') {
            this.filters.wasteOrderStatuses.isLoading = true;
          }
        }
        subscription = this.getWasteOrderStatuses().subscribe((data: WasteOrderStatus[]): void => {
          this.filterData.wasteOrderStatuses = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.wasteOrderStatuses !== 'undefined') {
              this.filters.wasteOrderStatuses.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getWasteOrderStatuses(): Observable<WasteOrderStatus[]> {
    return new Observable<WasteOrderStatus[]>((o: Subscriber<WasteOrderStatus[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.waste-order-statuses'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  wasteOrderTypes(): Observable<WasteOrderType[]> {
    return new Observable<WasteOrderType[]>((o: Subscriber<WasteOrderType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.wasteOrderTypes.length) {
        o.next(this.filterData.wasteOrderTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.wasteOrderTypes !== 'undefined') {
            this.filters.wasteOrderTypes.isLoading = true;
          }
        }
        subscription = this.getWasteOrderType().subscribe((data: WasteOrderType[]): void => {
          this.filterData.wasteOrderTypes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.wasteOrderTypes !== 'undefined') {
              this.filters.wasteOrderTypes.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getWasteOrderType(): Observable<WasteOrderType[]> {
    return new Observable<WasteOrderType[]>((o: Subscriber<WasteOrderType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.waste-order-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  wasteDataImportSeries(): Observable<WasteImportSerie[]> {
    return new Observable<WasteImportSerie[]>((o: Subscriber<WasteImportSerie[]>) => {
      let subscription: Subscription = null;

      // get data from server
      if (this.filters !== null) {
        if (typeof this.filters.wasteDataImportSeries !== 'undefined') {
          this.filters.wasteDataImportSeries.isLoading = true;
        }
      }
      subscription = this.getWasteDataImportSeries().subscribe((data: WasteImportSerie[]): void => {
        this.filterData.wasteDataImportSeries = data;
        o.next(data);
        if (this.filters !== null) {
          if (typeof this.filters.wasteDataImportSeries !== 'undefined') {
            this.filters.wasteDataImportSeries.isLoading = false;
          }
        }
      });

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getWasteDataImportSeries(): Observable<WasteImportSerie[]> {
    return new Observable<WasteImportSerie[]>((o: Subscriber<WasteImportSerie[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.waste-calendar.waste-data-import-series'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  wastePostalcodeImportSeries(): Observable<WasteImportSerie[]> {
    return new Observable<WasteImportSerie[]>((o: Subscriber<WasteImportSerie[]>) => {
      let subscription: Subscription = null;

      // get data from server
      if (this.filters !== null) {
        if (typeof this.filters.wastePostalcodeImportSeries !== 'undefined') {
          this.filters.wastePostalcodeImportSeries.isLoading = true;
        }
      }
      subscription = this.getWastePostalcodeImportSeries().subscribe((data: WasteImportSerie[]): void => {
        this.filterData.wastePostalcodeImportSeries = data;
        o.next(data);
        if (this.filters !== null) {
          if (typeof this.filters.wastePostalcodeImportSeries !== 'undefined') {
            this.filters.wastePostalcodeImportSeries.isLoading = false;
          }
        }
      });

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getWastePostalcodeImportSeries(): Observable<WasteImportSerie[]> {
    return new Observable<WasteImportSerie[]>((o: Subscriber<WasteImportSerie[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.waste-calendar.waste-postalcode-import-series'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  wasteDataRouteGroups(): Observable<WasteRouteGroup[]> {
    return new Observable<WasteRouteGroup[]>((o: Subscriber<WasteRouteGroup[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.wasteDataRouteGroups.length) {
        o.next(this.filterData.wasteDataRouteGroups);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.wasteDataRouteGroups !== 'undefined') {
            this.filters.wasteDataRouteGroups.isLoading = true;
          }
        }
        subscription = this.getWasteDataRouteGroups().subscribe((data: WasteRouteGroup[]): void => {
          this.filterData.wasteDataRouteGroups = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.wasteOrderTypes !== 'undefined') {
              this.filters.wasteOrderTypes.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getWasteDataRouteGroups(): Observable<WasteRouteGroup[]> {
    return new Observable<WasteRouteGroup[]>((o: Subscriber<WasteRouteGroup[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.waste-calendar.waste-data-route-groups'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  wastePostalcodeRouteGroups(): Observable<WasteRouteGroup[]> {
    return new Observable<WasteRouteGroup[]>((o: Subscriber<WasteRouteGroup[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.wastePostalcodeRouteGroups.length) {
        o.next(this.filterData.wastePostalcodeRouteGroups);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.wastePostalcodeRouteGroups !== 'undefined') {
            this.filters.wastePostalcodeRouteGroups.isLoading = true;
          }
        }
        subscription = this.getWastePostalcodeRouteGroups().subscribe((data: WasteRouteGroup[]): void => {
          this.filterData.wastePostalcodeRouteGroups = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.wasteOrderTypes !== 'undefined') {
              this.filters.wasteOrderTypes.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getWastePostalcodeRouteGroups(): Observable<WasteRouteGroup[]> {
    return new Observable<WasteRouteGroup[]>((o: Subscriber<WasteRouteGroup[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.waste-calendar.waste-postalcode-route-groups'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  wasteTypes(): Observable<WasteType[]> {
    return new Observable<WasteType[]>((o: Subscriber<WasteType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.wasteTypes.length) {
        o.next(this.filterData.wasteTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.wasteTypes !== 'undefined') {
            this.filters.wasteTypes.isLoading = true;
          }
        }
        subscription = this.getWasteType().subscribe((data: WasteType[]): void => {
          this.filterData.wasteTypes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.wasteTypes !== 'undefined') {
              this.filters.wasteTypes.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getWasteType(): Observable<WasteType[]> {
    return new Observable<WasteType[]>((o: Subscriber<WasteType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.waste-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  blacklistTypes(): Observable<BlacklistType[]> {
    return new Observable<DomainType[]>((o: Subscriber<BlacklistType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.blacklistTypes.length) {
        o.next(this.filterData.blacklistTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.blacklistTypes !== 'undefined') {
            this.filters.blacklistTypes.isLoading = true;
          }
        }
        subscription = this.getblacklistTypes().subscribe((data: BlacklistType[]): void => {
          this.filterData.blacklistTypes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.blacklistTypes !== 'undefined') {
              this.filters.blacklistTypes.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getblacklistTypes(): Observable<BlacklistType[]> {
    return new Observable<BlacklistType[]>((o: Subscriber<BlacklistType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.blacklist-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  changelogTypes(event: 'start' | 'stop' = 'start'): Observable<ChangelogType[]> {
    return new Observable<ChangelogType[]>((o: Subscriber<ChangelogType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.changelogTypes.length) {
        o.next(this.filterData.changelogTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.changelogTypes !== 'undefined') {
            this.filters.changelogTypes.isLoading = true;
          }
        }
        subscription = this.getChangelogTypes().subscribe((data: ChangelogType[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.changelogTypes !== 'undefined') {
              this.filters.changelogTypes.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.changelog-types.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.changelog-types.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.changelogTypes !== 'undefined') {
              this.filters.changelogTypes.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getChangelogTypes().subscribe((data: ChangelogType[]): void => {
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.changelogTypes !== 'undefined') {
                this.filters.changelogTypes.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getChangelogTypes(): Observable<ChangelogType[]> {
    return new Observable<ChangelogType[]>((o: Subscriber<ChangelogType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.knowledge-base.changelogs.types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  contentVisibility(): Observable<ContentVisibility[]> {
    return new Observable<ContentVisibility[]>((o: Subscriber<ContentVisibility[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.contentVisibility.length) {
        o.next(this.filterData.contentVisibility);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.contentVisibility !== 'undefined') {
            this.filters.contentVisibility.isLoading = true;
          }
        }
        subscription = this.getContentVisibility().subscribe((data: ContentVisibility[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.contentVisibility !== 'undefined') {
              this.filters.contentVisibility.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.content-visibility.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.content-visibility.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.contentVisibility !== 'undefined') {
              this.filters.contentVisibility.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getContentVisibility().subscribe((data: ContentVisibility[]): void => {
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.contentVisibility !== 'undefined') {
                this.filters.contentVisibility.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getContentVisibility(): Observable<ContentVisibility[]> {
    return new Observable<ContentVisibility[]>((o: Subscriber<ContentVisibility[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.content-visibility'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportNumbers(search: string, event: 'start' | 'stop' = 'start'): Observable<Report[]> {
    if (event === 'start') {
      return new Observable<Report[]>((o: Subscriber<Report[]>) => {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportNumbers !== 'undefined') {
            this.filters.reportNumbers.isLoading = true;
          }
        }
        const subscription: Subscription = this.getReportNumbers(search).subscribe((data: Report[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportNumbers !== 'undefined') {
              this.filters.reportNumbers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.reports.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.companies.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.reportNumbers !== 'undefined') {
                this.filters.reportNumbers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getReportNumbers(search).subscribe((data: Report[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.reportNumbers !== 'undefined') {
                  this.filters.reportNumbers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.reports.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.reports.changed');
      }
    }
  }

  getReportNumbers(search: string): Observable<Report[]> {
    return new Observable<Report[]>((o: Subscriber<Report[]>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('filters.report-numbers'), {search: search})
        .subscribe(
          (response: ServerResponse): void => {
            if (typeof response.data !== 'undefined') {
              o.next(response.data);
            } else {
              o.next([]);
            }
          }, (): void => {
            o.next([]);
          },
          (): void => o.complete());

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  mailEvents(event: 'start' | 'stop' = 'start'): Observable<MailEvent[]> {
    if (event === 'start') {
      return new Observable<MailEvent[]>((o: Subscriber<MailEvent[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.mailEvents.length) {
          o.next(this.filterData.mailEvents);
        } else {

          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.mailEvents !== 'undefined') {
              this.filters.mailEvents.isLoading = true;
            }
          }
          subscription = this.getMailEvents().subscribe((data: MailEvent[]): void => {
            this.filterData.mailEvents = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.mailEvents !== 'undefined') {
                this.filters.mailEvents.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.sent-emails.events', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.sent-emails.events');
            }

            if (this.filters !== null) {
              if (typeof this.filters.mailEvents !== 'undefined') {
                this.filters.mailEvents.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getMailEvents().subscribe((data: MailEvent[]): void => {
              this.filterData.mailEvents = data;
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.mailEvents !== 'undefined') {
                  this.filters.mailEvents.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.sent-emails.events');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.sent-emails.events');
      }
    }
  }

  getMailEvents(): Observable<MailEvent[]> {
    return new Observable<MailEvent[]>((o: Subscriber<MailEvent[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.sent-emails.events'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  mailLogLevels(event: 'start' | 'stop' = 'start'): Observable<MailLogLevel[]> {
    if (event === 'start') {
      return new Observable<MailLogLevel[]>((o: Subscriber<MailLogLevel[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.mailLogLevels.length) {
          o.next(this.filterData.mailLogLevels);
        } else {

          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.mailLogLevels !== 'undefined') {
              this.filters.mailLogLevels.isLoading = true;
            }
          }
          subscription = this.getMailLogLevels().subscribe((data: MailLogLevel[]): void => {
            this.filterData.mailLogLevels = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.mailLogLevels !== 'undefined') {
                this.filters.mailLogLevels.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.sent-emails.log-levels', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.sent-emails.log-levels');
            }

            if (this.filters !== null) {
              if (typeof this.filters.mailLogLevels !== 'undefined') {
                this.filters.mailLogLevels.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getMailLogLevels().subscribe((data: MailLogLevel[]): void => {
              this.filterData.mailLogLevels = data;
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.mailLogLevels !== 'undefined') {
                  this.filters.mailLogLevels.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.sent-emails.log-levels');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.sent-emails.log-levels');
      }
    }
  }

  getMailLogLevels(): Observable<MailLogLevel[]> {
    return new Observable<MailLogLevel[]>((o: Subscriber<MailLogLevel[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.sent-emails.log-levels'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  mailTags(event: 'start' | 'stop' = 'start'): Observable<MailTag[]> {
    if (event === 'start') {
      return new Observable<MailTag[]>((o: Subscriber<MailTag[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.mailTags.length) {
          o.next(this.filterData.mailTags);
        } else {

          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.mailTags !== 'undefined') {
              this.filters.mailTags.isLoading = true;
            }
          }
          subscription = this.getMailTags().subscribe((data: MailTag[]): void => {
            this.filterData.mailTags = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.mailTags !== 'undefined') {
                this.filters.mailTags.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.sent-emails.tags', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.sent-emails.tags');
            }

            if (this.filters !== null) {
              if (typeof this.filters.mailTags !== 'undefined') {
                this.filters.mailTags.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getMailTags().subscribe((data: MailTag[]): void => {
              this.filterData.mailTags = data;
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.mailTags !== 'undefined') {
                  this.filters.mailTags.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.sent-emails.tags');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.sent-emails.tags');
      }
    }
  }

  getMailTags(): Observable<MailTag[]> {
    return new Observable<MailTag[]>((o: Subscriber<MailTag[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.sent-emails.tags'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

}
