import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()

export class ApiEndpointsService {

  private endpoints: { key: string, route: string }[] | [] = [
    {key: 'public.default-organisation', route: 'v1/public/default-organisation'},

    {key: 'auth.login', route: 'auth/login'},
    {key: 'auth.logout', route: 'auth/logout'},
    {key: 'auth.forgot-password', route: 'auth/forgot-password'},
    {key: 'auth.reset-password.validate', route: 'auth/reset-password/:token?email=:email'},
    {key: 'auth.reset-password', route: 'auth/reset-password'},
    {key: 'auth.change-password', route: 'auth/change-password'},
    {key: 'auth.complete-account', route: 'auth/complete-account/:id?expires=:expires&signature=:signature'},
    {key: 'auth.account', route: 'auth/account'},
    {key: 'auth.impersonate', route: 'auth/impersonate'},

    {key: 'filters.organisations', route: 'v1/filters/organisations'},
    {key: 'filters.departments', route: 'v1/filters/departments'},
    {key: 'filters.departments-and-users', route: 'v1/filters/departments-and-users'},
    {key: 'filters.permissions', route: 'v1/filters/permissions'},
    {key: 'filters.permission-groups', route: 'v1/filters/permissions/groups'},
    {key: 'filters.roles', route: 'v1/filters/roles'},
    {key: 'filters.mail-types', route: 'v1/filters/mail-types'},
    {key: 'filters.cities', route: 'v1/filters/cities'},
    {key: 'filters.city', route: 'v1/filters/cities/:city_id'},
    {key: 'filters.report-numbers', route: 'v1/filters/report-numbers'},
    {key: 'filters.report-priorities', route: 'v1/filters/report-priorities'},
    {key: 'filters.report-statuses', route: 'v1/filters/report-statuses'},
    {key: 'filters.report-history-types', route: 'v1/filters/report-history-types'},
    {key: 'filters.users', route: 'v1/filters/users'},
    {key: 'filters.head-categories', route: 'v1/filters/head-categories'},
    {key: 'filters.head-categories.sub-categories', route: 'v1/filters/head-categories/:head_category_id/sub-categories'},
    {key: 'filters.sub-categories', route: 'v1/filters/sub-categories'},
    {key: 'filters.report-sources', route: 'v1/filters/report-sources'},
    {key: 'filters.address-book', route: 'v1/filters/address-book'},
    {key: 'filters.standard-reactions', route: 'v1/filters/standard-reactions'},
    {key: 'filters.report-history', route: 'v1/filters/report-history'},
    {key: 'filters.address-book-types', route: 'v1/filters/address-book-types'},
    {key: 'filters.knowledge-base.changelogs.types', route: 'v1/filters/knowledge-base/changelogs/types'},
    {key: 'filters.public-notification-types', route: 'v1/filters/public-notification-types'},
    {key: 'filters.content-visibility', route: 'v1/filters/content-visibility'},
    {key: 'filters.sent-emails.events', route: 'v1/filters/sent-emails/events'},
    {key: 'filters.sent-emails.log-levels', route: 'v1/filters/sent-emails/log-levels'},
    {key: 'filters.sent-emails.tags', route: 'v1/filters/sent-emails/tags'},
    {key: 'filters.holiday-types', route: 'v1/filters/holiday-types'},
    {key: 'filters.holiday-regional-scopes', route: 'v1/filters/holiday-regional-scopes'},
    {key: 'filters.holiday-temporal-scopes', route: 'v1/filters/holiday-temporal-scopes'},
    {key: 'filters.domain-types', route: 'v1/filters/domain-types'},
    {key: 'filters.blacklist-types', route: 'v1/filters/blacklist-types'},
    {key: 'filters.waste-order-statuses', route: 'v1/filters/waste-order-statuses'},
    {key: 'filters.waste-order-types', route: 'v1/filters/waste-order-types'},
    {key: 'filters.waste-calendar.waste-types', route: 'v1/filters/waste-calendar/waste-types'},
    {key: 'filters.waste-calendar.waste-data-import-series', route: 'v1/filters/waste-calendar/waste-data-import-series'},
    {key: 'filters.waste-calendar.waste-data-route-groups', route: 'v1/filters/waste-calendar/waste-data-route-groups'},
    {key: 'filters.waste-calendar.waste-postalcode-import-series', route: 'v1/filters/waste-calendar/postalcode-import-series'},
    {key: 'filters.waste-calendar.waste-postalcode-route-groups', route: 'v1/filters/waste-calendar/waste-postalcode-route-groups'},

    {key: 'routering.organisations.index', route: 'v1/configuration/organisations'},
    {key: 'routering.organisations.create', route: 'v1/configuration/organisations'},
    {key: 'routering.organisations.read', route: 'v1/configuration/organisations/:id'},
    {key: 'routering.organisations.sort', route: 'v1/configuration/organisations/sort'},
    {key: 'routering.organisations.update', route: 'v1/configuration/organisations/:id'},
    {key: 'routering.organisations.switch', route: 'v1/configuration/organisations/:id/switch'},
    {key: 'routering.organisations.delete', route: 'v1/configuration/organisations/:id'},
    {key: 'routering.organisations.restore', route: 'v1/configuration/organisations/:id/restore'},
    {key: 'routering.departments.index', route: 'v1/configuration/departments'},
    {key: 'routering.departments.create', route: 'v1/configuration/departments'},
    {key: 'routering.departments.read', route: 'v1/configuration/departments/:id'},
    {key: 'routering.departments.update', route: 'v1/configuration/departments/:id'},
    {key: 'routering.departments.delete', route: 'v1/configuration/departments/:id'},
    {key: 'routering.departments.restore', route: 'v1/configuration/departments/:id/restore'},
    {key: 'routering.roles.index', route: 'v1/configuration/roles'},
    {key: 'routering.roles.overview', route: 'v1/configuration/roles/overview'},
    {key: 'routering.roles.create', route: 'v1/configuration/roles'},
    {key: 'routering.roles.read', route: 'v1/configuration/roles/:id'},
    {key: 'routering.roles.update', route: 'v1/configuration/roles/:id'},
    {key: 'routering.roles.delete', route: 'v1/configuration/roles/:id'},
    {key: 'routering.users.index', route: 'v1/configuration/users'},
    {key: 'routering.users.create', route: 'v1/configuration/users'},
    {key: 'routering.users.read', route: 'v1/configuration/users/:id'},
    {key: 'routering.users.update', route: 'v1/configuration/users/:id'},
    {key: 'routering.users.delete', route: 'v1/configuration/users/:id'},
    {key: 'routering.users.restore', route: 'v1/configuration/users/:id/restore'},
    {key: 'routering.users.resend-invitation', route: 'v1/configuration/users/:id/resend-invitation'},
    {key: 'routering.head-categories.index', route: 'v1/configuration/head-categories'},
    {key: 'routering.head-categories.create', route: 'v1/configuration/head-categories'},
    {key: 'routering.head-categories.read', route: 'v1/configuration/head-categories/:id'},
    {key: 'routering.head-categories.update', route: 'v1/configuration/head-categories/:id'},
    {key: 'routering.head-categories.delete', route: 'v1/configuration/head-categories/:id'},
    {key: 'routering.head-categories.restore', route: 'v1/configuration/head-categories/:id/restore'},
    {key: 'routering.sub-categories.index', route: 'v1/configuration/head-categories/:head_category_id/sub-categories'},
    {key: 'routering.sub-categories.create', route: 'v1/configuration/head-categories/:head_category_id/sub-categories'},
    {key: 'routering.sub-categories.read', route: 'v1/configuration/head-categories/:head_category_id/sub-categories/:id'},
    {key: 'routering.sub-categories.update', route: 'v1/configuration/head-categories/:head_category_id/sub-categories/:id'},
    {key: 'routering.sub-categories.delete', route: 'v1/configuration/head-categories/:head_category_id/sub-categories/:id'},
    {key: 'routering.sub-categories.restore', route: 'v1/configuration/head-categories/:head_category_id/sub-categories/:id/restore'},

    {key: 'configuration.cities.index', route: 'v1/configuration/cities'},
    {key: 'configuration.cities.create', route: 'v1/configuration/cities'},
    {key: 'configuration.cities.read', route: 'v1/configuration/cities/:id'},
    {key: 'configuration.cities.update', route: 'v1/configuration/cities/:id'},
    {key: 'configuration.cities.restore', route: 'v1/configuration/cities/:id/restore'},
    {key: 'configuration.cities.delete', route: 'v1/configuration/cities/:id'},

    {key: 'configuration.address-book.index', route: 'v1/configuration/address-book'},
    {key: 'configuration.address-book.create', route: 'v1/configuration/address-book'},
    {key: 'configuration.address-book.read', route: 'v1/configuration/address-book/:id'},
    {key: 'configuration.address-book.update', route: 'v1/configuration/address-book/:id'},
    {key: 'configuration.address-book.delete', route: 'v1/configuration/address-book/:id'},
    {key: 'configuration.address-book.restore', route: 'v1/configuration/address-book/:id/restore'},
    {key: 'configuration.email-templates.index', route: 'v1/configuration/email-templates'},
    {key: 'configuration.email-templates.create', route: 'v1/configuration/email-templates'},
    {key: 'configuration.email-templates.read', route: 'v1/configuration/email-templates/:id'},
    {key: 'configuration.email-templates.update', route: 'v1/configuration/email-templates/:id'},
    {key: 'configuration.email-templates.delete', route: 'v1/configuration/email-templates/:id'},
    {key: 'configuration.standard-reactions.index', route: 'v1/configuration/standard-reactions'},
    {key: 'configuration.standard-reactions.create', route: 'v1/configuration/standard-reactions'},
    {key: 'configuration.standard-reactions.read', route: 'v1/configuration/standard-reactions/:id'},
    {key: 'configuration.standard-reactions.update', route: 'v1/configuration/standard-reactions/:id'},
    {key: 'configuration.standard-reactions.delete', route: 'v1/configuration/standard-reactions/:id'},
    {key: 'configuration.standard-reactions.restore', route: 'v1/configuration/standard-reactions/:id/restore'},

    {key: 'configuration.public-notifications.index', route: 'v1/configuration/public-notifications'},
    {key: 'configuration.public-notifications.create', route: 'v1/configuration/public-notifications'},
    {key: 'configuration.public-notifications.read', route: 'v1/configuration/public-notifications/:id'},
    {key: 'configuration.public-notifications.update', route: 'v1/configuration/public-notifications/:id'},
    {key: 'configuration.public-notifications.delete', route: 'v1/configuration/public-notifications/:id'},
    {key: 'configuration.public-notifications.restore', route: 'v1/configuration/public-notifications/:id/restore'},

    {key: 'configuration.public-notification-types.index', route: 'v1/configuration/public-notification-types'},
    {key: 'configuration.public-notification-types.create', route: 'v1/configuration/public-notification-types'},
    {key: 'configuration.public-notification-types.read', route: 'v1/configuration/public-notification-types/:id'},
    {key: 'configuration.public-notification-types.update', route: 'v1/configuration/public-notification-types/:id'},
    {key: 'configuration.public-notification-types.delete', route: 'v1/configuration/public-notification-types/:id'},
    {key: 'configuration.public-notification-types.restore', route: 'v1/configuration/public-notification-types/:id/restore'},

    {key: 'configuration.knowledge-base.index', route: 'v1/configuration/knowledge-base'},
    {key: 'configuration.knowledge-base.create', route: 'v1/configuration/knowledge-base'},
    {key: 'configuration.knowledge-base.create-upload-media', route: 'v1/configuration/knowledge-base/media'},
    {key: 'configuration.knowledge-base.read', route: 'v1/configuration/knowledge-base/:id'},
    {key: 'configuration.knowledge-base.update', route: 'v1/configuration/knowledge-base/:id'},
    {key: 'configuration.knowledge-base.update-upload-media', route: 'v1/configuration/knowledge-base/:id/media'},
    {key: 'configuration.knowledge-base.delete', route: 'v1/configuration/knowledge-base/:id'},
    {key: 'configuration.knowledge-base.restore', route: 'v1/configuration/knowledge-base/:id/restore'},
    {key: 'configuration.knowledge-base-articles.index', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles'},
    {key: 'configuration.knowledge-base-articles.create', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles'},
    {key: 'configuration.knowledge-base-articles.create-upload-media', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/media'},
    {key: 'configuration.knowledge-base-articles.read', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id'},
    {key: 'configuration.knowledge-base-articles.update', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id'},
    {key: 'configuration.knowledge-base-articles.update-upload-media', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id/media'},
    {key: 'configuration.knowledge-base-articles.delete', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id'},
    {key: 'configuration.knowledge-base-articles.restore', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id/restore'},
    {key: 'configuration.knowledge-base.faq.index', route: 'v1/configuration/knowledge-base/faq'},
    {key: 'configuration.knowledge-base.faq.create', route: 'v1/configuration/knowledge-base/faq'},
    {key: 'configuration.knowledge-base.faq.read', route: 'v1/configuration/knowledge-base/faq/:id'},
    {key: 'configuration.knowledge-base.faq.update', route: 'v1/configuration/knowledge-base/faq/:id'},
    {key: 'configuration.knowledge-base.faq.delete', route: 'v1/configuration/knowledge-base/faq/:id'},
    {key: 'configuration.knowledge-base.faq.restore', route: 'v1/configuration/knowledge-base/faq/:id/restore'},
    {key: 'configuration.knowledge-base.support-contact-details', route: 'v1/configuration/knowledge-base/support-contact-details'},

    {key: 'configuration.knowledge-base.changelogs.index', route: 'v1/configuration/knowledge-base/changelogs'},
    {key: 'configuration.knowledge-base.changelogs.create', route: 'v1/configuration/knowledge-base/changelogs'},
    {key: 'configuration.knowledge-base.changelogs.read', route: 'v1/configuration/knowledge-base/changelogs/:id'},
    {key: 'configuration.knowledge-base.changelogs.update', route: 'v1/configuration/knowledge-base/changelogs/:id'},
    {key: 'configuration.knowledge-base.changelogs.delete', route: 'v1/configuration/knowledge-base/changelogs/:id'},
    {key: 'configuration.knowledge-base.changelogs.articles.index', route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles'},
    {key: 'configuration.knowledge-base.changelogs.articles.create', route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles'},
    {key: 'configuration.knowledge-base.changelogs.articles.create-upload-media', route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/media'},
    {key: 'configuration.knowledge-base.changelogs.articles.read', route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/:id'},
    {key: 'configuration.knowledge-base.changelogs.articles.update', route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/:id'},
    {key: 'configuration.knowledge-base.changelogs.articles.update-upload-media', route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/:id/media'},
    {key: 'configuration.knowledge-base.changelogs.articles.delete', route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/:id'},
    {key: 'configuration.knowledge-base.changelogs.types.index', route: 'v1/configuration/knowledge-base/changelogs/types'},
    {key: 'configuration.knowledge-base.changelogs.types.create', route: 'v1/configuration/knowledge-base/changelogs/types'},
    {key: 'configuration.knowledge-base.changelogs.types.read', route: 'v1/configuration/knowledge-base/changelogs/types/:id'},
    {key: 'configuration.knowledge-base.changelogs.types.update', route: 'v1/configuration/knowledge-base/changelogs/types/:id'},
    {key: 'configuration.knowledge-base.changelogs.types.delete', route: 'v1/configuration/knowledge-base/changelogs/types/:id'},
    {key: 'configuration.reports.priorities.index', route: 'v1/configuration/report-priorities'},
    {key: 'configuration.reports.priorities.create', route: 'v1/configuration/report-priorities'},
    {key: 'configuration.reports.priorities.read', route: 'v1/configuration/report-priorities/:id'},
    {key: 'configuration.reports.priorities.update', route: 'v1/configuration/report-priorities/:id'},
    {key: 'configuration.reports.priorities.delete', route: 'v1/configuration/report-priorities/:id'},
    {key: 'configuration.reports.priorities.restore', route: 'v1/configuration/report-priorities/:id/restore'},
    {key: 'configuration.reports.priorities.sort', route: 'v1/configuration/report-priorities/sort'},
    {key: 'configuration.reports.statuses.index', route: 'v1/configuration/report-statuses'},
    {key: 'configuration.reports.statuses.create', route: 'v1/configuration/report-statuses'},
    {key: 'configuration.reports.statuses.read', route: 'v1/configuration/report-statuses/:id'},
    {key: 'configuration.reports.statuses.update', route: 'v1/configuration/report-statuses/:id'},
    {key: 'configuration.reports.statuses.delete', route: 'v1/configuration/report-statuses/:id'},
    {key: 'configuration.reports.statuses.restore', route: 'v1/configuration/report-statuses/:id/restore'},
    {key: 'configuration.reports.statuses.sort', route: 'v1/configuration/report-statuses/sort'},
    {key: 'configuration.reports.sources.index', route: 'v1/configuration/report-sources'},
    {key: 'configuration.reports.sources.create', route: 'v1/configuration/report-sources'},
    {key: 'configuration.reports.sources.read', route: 'v1/configuration/report-sources/:id'},
    {key: 'configuration.reports.sources.update', route: 'v1/configuration/report-sources/:id'},
    {key: 'configuration.reports.sources.delete', route: 'v1/configuration/report-sources/:id'},
    {key: 'configuration.reports.sources.restore', route: 'v1/configuration/report-sources/:id/restore'},
    {key: 'configuration.reports.sources.sort', route: 'v1/configuration/report-sources/sort'},
    {key: 'configuration.reports.histories.index', route: 'v1/configuration/report-history'},
    {key: 'configuration.reports.histories.read', route: 'v1/configuration/report-history/:id'},
    {key: 'configuration.reports.histories.update', route: 'v1/configuration/report-history/:id'},
    {key: 'configuration.holidays.index', route: 'v1/configuration/holidays'},
    {key: 'configuration.holidays.create', route: 'v1/configuration/holidays'},
    {key: 'configuration.holidays.read', route: 'v1/configuration/holidays/:id'},
    {key: 'configuration.holidays.update', route: 'v1/configuration/holidays/:id'},
    {key: 'configuration.holidays.delete', route: 'v1/configuration/holidays/:id'},
    {key: 'configuration.blacklist.index', route: 'v1/configuration/blacklist'},
    {key: 'configuration.blacklist.create', route: 'v1/configuration/blacklist'},
    {key: 'configuration.blacklist.read', route: 'v1/configuration/blacklist/:id'},
    {key: 'configuration.blacklist.update', route: 'v1/configuration/blacklist/:id'},
    {key: 'configuration.blacklist.delete', route: 'v1/configuration/blacklist/:id'},
    {key: 'configuration.waste-order.status.index', route: 'v1/configuration/waste-order-statuses'},
    {key: 'configuration.waste-order.status.read', route: 'v1/configuration/waste-order-statuses/:id'},
    {key: 'configuration.waste-order.status.update', route: 'v1/configuration/waste-order-statuses/:id'},
    {key: 'configuration.waste-order.type.index', route: 'v1/configuration/waste-order-types'},
    {key: 'configuration.waste-order.type.read', route: 'v1/configuration/waste-order-types/:id'},
    {key: 'configuration.waste-order.type.update', route: 'v1/configuration/waste-order-types/:id'},

    {key: 'overview', route: 'v1/overview'},

    {key: 'reports.index', route: 'v1/reports'},
    {key: 'reports.overview', route: 'v1/reports/overview'},
    {key: 'reports.overview.export', route: 'v1/reports/overview/export'},
    {key: 'reports.review', route: 'v1/reports/review'},
    {key: 'reports.closed', route: 'v1/reports/closed'},
    {key: 'reports.all', route: 'v1/reports/all'},
    {key: 'reports.count', route: 'v1/reports/count'},
    {key: 'reports.create', route: 'v1/reports'},
    {key: 'reports.read', route: 'v1/reports/:id'},
    {key: 'reports.update', route: 'v1/reports/:id'},
    {key: 'reports.delete', route: 'v1/reports/:id'},
    {key: 'reports.handle', route: 'v1/reports/:id/handle'},
    {key: 'reports.approve', route: 'v1/reports/:id/approve'},
    {key: 'reports.copy', route: 'v1/reports/:id/copy'},
    {key: 'reports.reject', route: 'v1/reports/:id/reject'},
    {key: 'reports.restore', route: 'v1/reports/:id/restore'},
    {key: 'reports.download-attachment', route: 'v1/reports/:id/attachments/:attachment_id'},
    {key: 'reports.download-handling-attachment', route: 'v1/reports/:id/handling/attachments/:attachment_id'},
    {key: 'reports.export', route: 'v1/reports/export/:fileType'},
    {key: 'reports.rotate-photo', route: 'v1/reports/:id/rotate-photo/:photoId/:direction'},

    {key: 'reports.reassign', route: 'v1/reports/:id/reassign'},
    {key: 'reports.forward', route: 'v1/reports/:id/forward'},
    {key: 'reports.history', route: 'v1/reports/:id/history'},
    {key: 'reports.resend-open-confirmation', route: 'v1/reports/:id/resend-open-confirmation'},

    {key: 'reports.multiple.reject', route: 'v1/reports/multiple/reject'},
    {key: 'reports.multiple.resolve', route: 'v1/reports/multiple/resolve'},

    {key: 'reporters.index', route: 'v1/reporters'},
    {key: 'reporters.search', route: 'v1/reporters/search'},
    {key: 'reporters.create', route: 'v1/reporters'},
    {key: 'reporters.read', route: 'v1/reporters/:id'},
    {key: 'reporters.update', route: 'v1/reporters/:id'},
    {key: 'reporters.delete', route: 'v1/reporters/:id'},
    {key: 'reporters.mass-delete', route: 'v1/reporters'},
    {key: 'reporters.restore', route: 'v1/reporters/:id/restore'},

    {key: 'waste-orders.open', route: 'v1/waste-orders'},
    {key: 'waste-orders.closed', route: 'v1/waste-orders/closed'},
    {key: 'waste-orders.all', route: 'v1/waste-orders/all'},
    {key: 'waste-orders.export', route: 'v1/waste-orders/export'},
    {key: 'waste-orders.count', route: 'v1/waste-orders/count'},
    {key: 'waste-orders.create', route: 'v1/waste-orders'},
    {key: 'waste-orders.read', route: 'v1/waste-orders/:id'},
    {key: 'waste-orders.update', route: 'v1/waste-orders/:id'},
    {key: 'waste-orders.delete', route: 'v1/waste-orders/:id'},
    {key: 'waste-orders.update-actual-quantity', route: 'v1/waste-orders/:id/update-actual-quantity'},
    {key: 'waste-orders.update-actual-quantities', route: 'v1/waste-orders/update-actual-quantities'},
    {key: 'waste-orders.restore', route: 'v1/waste-orders/:id/restore'},
    {key: 'waste-orders.history', route: 'v1/waste-orders/:id/history'},
    {key: 'waste-orders.resend-open-confirmation', route: 'v1/waste-orders/:id/resend-open-confirmation'},

    {key: 'waste-calendar.waste-types.index', route: 'v1/waste-calendar/waste-types'},
    {key: 'waste-calendar.waste-types.create', route: 'v1/waste-calendar/waste-types'},
    {key: 'waste-calendar.waste-types.read', route: 'v1/waste-calendar/waste-types/:id'},
    {key: 'waste-calendar.waste-types.update', route: 'v1/waste-calendar/waste-types/:id'},
    {key: 'waste-calendar.waste-types.delete', route: 'v1/waste-calendar/waste-types/:id'},
    {key: 'waste-calendar.waste-data.index', route: 'v1/waste-calendar/waste-data'},
    {key: 'waste-calendar.waste-data.import', route: 'v1/waste-calendar/waste-data'},
    {key: 'waste-calendar.waste-data.set-active', route: 'v1/waste-calendar/waste-data/:id/set-active'},
    {key: 'waste-calendar.waste-data.export', route: 'v1/waste-calendar/waste-data/:id/export'},
    {key: 'waste-calendar.waste-data.delete', route: 'v1/waste-calendar/waste-data/:id'},
    {key: 'waste-calendar.postalcodes.index', route: 'v1/waste-calendar/postalcodes'},
    {key: 'waste-calendar.postalcodes.import', route: 'v1/waste-calendar/postalcodes'},
    {key: 'waste-calendar.postalcodes.set-active', route: 'v1/waste-calendar/postalcodes/:id/set-active'},
    {key: 'waste-calendar.postalcodes.export', route: 'v1/waste-calendar/postalcodes/:id/export'},
    {key: 'waste-calendar.postalcodes.delete', route: 'v1/waste-calendar/postalcodes/:id'},
    {key: 'waste-calendar.waste-calendar', route: 'v1/waste-calendar/waste-calendar'},

    {key: 'knowledge-base.contact-details.index', route: 'v1/knowledge-base/support-contact-details'},
    {key: 'knowledge-base.faq.index', route: 'v1/knowledge-base/faq'},
    {key: 'knowledge-base.support-request', route: 'v1/knowledge-base/support-request'},
    {key: 'knowledge-base.kb.index', route: 'v1/knowledge-base'},
    {key: 'knowledge-base.kb.read', route: 'v1/knowledge-base/:url'},
    {key: 'knowledge-base.kb.articles', route: 'v1/knowledge-base/:articles/articles'},
    {key: 'knowledge-base.kb.article', route: 'v1/knowledge-base/:articles/articles/:article'},
    {key: 'knowledge-base.changelogs.index', route: 'v1/knowledge-base/changelogs'},
    {key: 'knowledge-base.changelogs.read', route: 'v1/knowledge-base/changelogs/:url'},
    {key: 'knowledge-base.changelogs.articles', route: 'v1/knowledge-base/changelogs/:changelog_url/articles'},

    {key: 'statistics.monthly-report', route: 'v1/statistics/monthly-report'},
    {key: 'statistics.monthly-report.export', route: 'v1/statistics/monthly-report/export'},
    {key: 'statistics.yearly-report', route: 'v1/statistics/yearly-report'},
    {key: 'statistics.yearly-report.export', route: 'v1/statistics/yearly-report/export'},
    {key: 'statistics.yearly-communication-report', route: 'v1/statistics/yearly-communication-report'},
    {key: 'statistics.yearly-communication-report.export', route: 'v1/statistics/yearly-communication-report/export'},
    {key: 'statistics.sources-report', route: 'v1/statistics/sources-report'},
    {key: 'statistics.sources-report.export', route: 'v1/statistics/sources-report/export'},
    {key: 'statistics.monthly-categories-report', route: 'v1/statistics/monthly-categories-report'},
    {key: 'statistics.monthly-categories-report.export', route: 'v1/statistics/monthly-categories-report/export'},
    {key: 'statistics.custom-compiled-report', route: 'v1/statistics/custom-compiled-report'},
    {key: 'statistics.custom-compiled-report.export', route: 'v1/statistics/custom-compiled-report/export'},
    {key: 'statistics.survey-report', route: 'v1/statistics/survey-report'},
    {key: 'statistics.survey-report.export', route: 'v1/statistics/survey-report/export'},
    {key: 'statistics.ranklist', route: 'v1/statistics/ranklist'},
    {key: 'statistics.ranklist.export', route: 'v1/statistics/ranklist/export'},

    {key: 'archive.anonymized', route: 'v1/archive/anonymized'},
    {key: 'archive.anonymized.export', route: 'v1/archive/anonymized/export'},
    {key: 'archive.anonymized.read', route: 'v1/archive/anonymized/:id'},
    {key: 'archive.archivering.index', route: 'v1/archive/archivering'},
    {key: 'archive.archivering.create', route: 'v1/archive/archivering'},
    {key: 'archive.archivering.read', route: 'v1/archive/archivering/:id'},
    {key: 'archive.archivering.update', route: 'v1/archive/archivering/:id'},
    {key: 'archive.archivering.delete', route: 'v1/archive/archivering/:id'},
    {key: 'archive.archivering.users', route: 'v1/archive/archivering/users'},
    {key: 'archive.archivering.users.all', route: 'v1/archive/archivering/users?all=true'},
    {key: 'archive.archivering.invite', route: 'v1/archive/archivering/:id/invite'},
    {key: 'archive.archivering.resend-invitation', route: 'v1/archive/archivering/:id/resend-invitation'},
    {key: 'archive.archivering.approve', route: 'v1/archive/archivering/:id/approve'},
    {key: 'archive.archivering.reject', route: 'v1/archive/archivering/:id/reject'},
    {key: 'archive.archivering.destroy', route: 'v1/archive/archivering/:id/destroy'},
    {key: 'archive.archivering.export', route: 'v1/archive/archivering/:id/export'},
    {key: 'archive.archivering.certificate', route: 'v1/archive/archivering/:id/certificate'},
    {key: 'archive.archivering.reports', route: 'v1/archive/archivering/:id/reports'},
    {key: 'archive.archivering.reports.delete', route: 'v1/archive/archivering/:archive_collection_id/reports/:id'},
    {key: 'archive.archivering.logs', route: 'v1/archive/archivering/:id/logs'},

    {key: 'sent-emails.index', route: 'v1/sent-emails'},
    {key: 'sent-emails.message', route: 'v1/sent-emails/:id/message'},
    {key: 'sent-emails.logs', route: 'v1/sent-emails/:id/logs'},

    {key: 'sent-emails.events.index', route: 'v1/sent-emails/events'},
    {key: 'sent-emails.events.create', route: 'v1/sent-emails/events'},
    {key: 'sent-emails.events.read', route: 'v1/sent-emails/events/:id'},
    {key: 'sent-emails.events.update', route: 'v1/sent-emails/events/:id'},
    {key: 'sent-emails.events.delete', route: 'v1/sent-emails/events/:id'},
  ];

  constructor() {
  }

  get(name: string, params?: any): string | null {
    const index: number = _.findIndex(this.endpoints, d => d.key === name);

    if (index !== -1) {
      if (params) {
        const url: string = this.endpoints[index].route;

        return url.replace(/(:[a-zA-Z_]+)/g, m => {
          return params[m];
        });
      } else {
        return this.endpoints[index].route;
      }
    }

    throw new Error('Unknown route URL requested: ' + name);
  }
}
