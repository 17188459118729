<!--suppress AngularUndefinedTag -->
<ng-container *ngIf="reportPrintService.report">
  <table class="print-container">
    <thead class="print-header">
    <tr>
      <th>
        <h1>
          Overzicht melding {{ reportPrintService.report.number }}
          -
          {{ reportPrintService.report.created_at | date:'dd-MM-yy' }}
        </h1>
      </th>
    </tr>
    </thead>
    <tbody class="print-content">
    <tr>
      <td>
        <div class="content">
          <h2>Algemene gegevens</h2>
          <table style="width:100%">
            <tr>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Registratienummer:</th>
                    <td>{{ reportPrintService.report.number }}</td>
                  </tr>
                  <tr>
                    <th>Datum en tijd ingediend:</th>
                    <td>{{ reportPrintService.report.created_at | date:'dd-MM-yy HH:mm' }}</td>
                  </tr>
                  <tr>
                    <th>Datum en tijd geplanned:</th>
                    <td>{{ reportPrintService.report.handling?.planned_at | date:'dd-MM-yy HH:mm' }}</td>
                  </tr>
                  <tr>
                    <th>Datum en tijd toegekend:</th>
                    <td>{{ reportPrintService.report.handling?.assigned_at | date:'dd-MM-yy HH:mm' }}</td>
                  </tr>
                  <tr>
                    <th>Datum en tijd afgehandeld:</th>
                    <td>{{ reportPrintService.report.handling?.closed_at | date:'dd-MM-yy HH:mm' }}</td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Behandelaar:</th>
                    <td>{{ reportPrintService.report.assigned_to }}</td>
                  </tr>
                  <tr>
                    <th>Status melding:</th>
                    <td [style.background]="reportPrintService.report.status.color">
                      {{ reportPrintService.report.status.display_name }}
                    </td>
                  </tr>
                  <tr>
                    <th>Prioriteit:</th>
                    <td [style.background]="reportPrintService.report.priority?.color">
                      {{ reportPrintService.report.priority?.name }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Omschrijving melding</h2>
          <table style="width:100%">
            <tr>
              <th>Wijze van melden:</th>
              <td>{{ reportPrintService.report.source?.name }}</td>
            </tr>
            <tr>
              <th>Categorieën melding:</th>
              <td>
                <ng-container *ngIf="reportPrintService.report.head_category">
                  {{ reportPrintService.report.head_category?.name }}
                  <ng-container *ngIf="reportPrintService.report.sub_category">
                    >
                    {{ reportPrintService.report.sub_category?.name }}
                  </ng-container>
                </ng-container>
              </td>
            </tr>
            <tr>
              <th>Toelichting:</th>
              <td>{{ reportPrintService.report.description }}</td>
            </tr>
            <tr>
              <th>Interne notitie:</th>
              <td>{{ reportPrintService.report.internal_notes }}</td>
            </tr>
            <tr>
              <th>Bijlagen:</th>
              <td>
                <ng-container *ngIf="!reportPrintService.report.attachments?.total">
                  Geen bijlagen
                </ng-container>
                <ng-container *ngIf="reportPrintService.report.attachments?.total">
                  <div *ngIf="reportPrintService.report.attachments.media.length" class="media">
                    <div *ngFor="let photo of reportPrintService.report.attachments.media; let i = index"
                         class="media-div">
                      <img *ngIf="photo.type === 'image'" [src]="photo.thumb" alt="">
                      <video *ngIf="photo.type === 'video'" [src]="photo.src"></video>
                    </div>
                  </div>
                  <div *ngIf="reportPrintService.report.attachments.documents.length">
                    <ng-container *ngFor="let document of reportPrintService.report.attachments.documents">
                      <ng-container *ngIf="document.type === 'document'">
                        {{ document.original_filename }}<br>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Locatie melding</h2>
          <table style="width:100%">
            <tr>
              <td style="width:50%;">
                <table style="width:100%">
                  <tr>
                    <th>Onbekende locatie:</th>
                    <td>{{ reportPrintService.report.complaint.location_unknown === false ? 'Nee' : 'Ja' }}</td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table *ngIf="!reportPrintService.report.complaint.location_unknown" style="width:100%">
                  <tr>
                    <th>Latitude:</th>
                    <td>{{ reportPrintService.report.complaint.lat }}</td>
                  </tr>
                  <tr>
                    <th>Longitude:</th>
                    <td>{{ reportPrintService.report.complaint.lng }}</td>
                  </tr>
                  <tr>
                    <th>Postcode:</th>
                    <td>{{ reportPrintService.report.complaint.postalcode }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer:</th>
                    <td>{{ reportPrintService.report.complaint.house_number_with_addition }}</td>
                  </tr>
                  <tr>
                    <th>Straatnaam:</th>
                    <td>{{ reportPrintService.report.complaint.streetname }}</td>
                  </tr>
                  <tr>
                    <th>Plaats:</th>
                    <td>{{ reportPrintService.report.complaint.city }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table style="width:100%">
            <tr *ngIf="reportPrintService.report.complaint.location_description">
              <th>Omschrijf locatie:</th>
              <td class="full-width">{{ reportPrintService.report.complaint.location_description }}</td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Contactgegevens melder</h2>
          <table style="width:100%">
            <tr>
              <td style="width:50%">
                <table *ngIf="reportPrintService.report.reporter?.anonymous" style="width:100%">
                  <tr>
                    <th>Anoniem:</th>
                    <td>{{ reportPrintService.report.reporter.anonymous === true ? 'Ja' : 'Nee' }}</td>
                  </tr>
                </table>
                <table *ngIf="!reportPrintService.report.reporter?.anonymous" style="width:100%">
                  <tr>
                    <th>Anoniem:</th>
                    <td>{{ reportPrintService.report.reporter.anonymous === true ? 'Ja' : 'Nee' }}</td>
                  </tr>
                  <tr>
                    <th>Aanhef:</th>
                    <td>{{ reportPrintService.report.reporter.prefix }}</td>
                  </tr>
                  <tr>
                    <th>Voorletters:</th>
                    <td>{{ reportPrintService.report.reporter.initials }}</td>
                  </tr>
                  <tr>
                    <th>Achternaam:</th>
                    <td>{{ reportPrintService.report.reporter.lastname }}</td>
                  </tr>
                  <tr>
                    <th>Telefoonnummer:</th>
                    <td>{{ reportPrintService.report.reporter.phone }}</td>
                  </tr>
                  <tr>
                    <th>E-mail:</th>
                    <td>{{ reportPrintService.report.reporter.email }}</td>
                  </tr>
                  <tr>
                    <th>Verstuur e-mail notificaties:</th>
                    <td>{{ reportPrintService.report.reporter.notifications === true ? 'Ja' : 'Nee' }}</td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table *ngIf="!reportPrintService.report.reporter?.anonymous" style="width:100%">
                  <tr>
                    <th>Postcode:</th>
                    <td>{{ reportPrintService.report.reporter.postalcode }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer:</th>
                    <td>{{ reportPrintService.report.reporter.house_number_with_addition }}</td>
                  </tr>
                  <tr>
                    <th>Straatnaam:</th>
                    <td>{{ reportPrintService.report.reporter.streetname }}</td>
                  </tr>
                  <tr>
                    <th>Plaats:</th>
                    <td>{{ reportPrintService.report.reporter.city }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Afhandeling</h2>
          <table width="100%">
            <tr>
              <th>Intern toelichting afhandeling:</th>
              <td>
                <ng-container *ngIf="reportPrintService.report.handling?.internal_notes.length">
                  <div *ngFor="let note of reportPrintService.report.handling?.internal_notes" class="note-container">
                    <h3 class="note-header">
                      {{ note.created_at | date:'dd-MM-yy HH:mm' }}
                      <ng-container *ngIf="note.user">{{ note.user.name }}</ng-container>
                      <ng-container *ngIf="!note.user">Onbekend</ng-container>
                      <small *ngIf="note.is_updated"> (aangepast)</small>
                    </h3>
                    <div [innerHTML]="note.notes" class="note-content"></div>
                  </div>
                </ng-container>
              </td>
            </tr>
          </table>
          <h2>Communicatie melder</h2>
          <table style="width:100%">
            <tr>
              <th>Bericht naar de melder:</th>
              <td [innerHTML]="reportPrintService.report?.handling?.last_note_to_reporter"></td>
            </tr>
            <tr *ngIf="reportPrintService.report?.handling?.reopen_notes?.length">
              <th>Verklaring melding heropenen:</th>
              <td>
                <div *ngFor="let note of reportPrintService.report.handling?.reopen_notes" class="note-container">
                  <h3 class="note-header">
                    {{ note.created_at | date:'dd-MM-yy HH:mm' }}
                    <ng-container *ngIf="note.user">{{ note.user.name }}</ng-container>
                    <ng-container *ngIf="!note.user">Onbekend</ng-container>
                  </h3>
                  <div [innerHTML]="note.notes" class="note-content"></div>
                </div>
              </td>
            </tr>
            <tr *ngIf="reportPrintService.report?.handling?.future_notes?.length">
              <th>Tussenbericht notitie:</th>
              <td>
                <div *ngFor="let note of reportPrintService.report.handling?.future_notes" class="note-container">
                  <h3 class="note-header">
                    {{ note.created_at | date:'dd-MM-yy HH:mm' }}
                    <ng-container *ngIf="note.user">{{ note.user.name }}</ng-container>
                    <ng-container *ngIf="!note.user">Onbekend</ng-container>
                  </h3>
                  <div [innerHTML]="note.notes" class="note-content"></div>
                </div>
              </td>
            </tr>
            <tr *ngIf="reportPrintService.report?.handling?.reassign_notes?.length">
              <th>Nieuw toegewezen melding:</th>
              <td>
                <div *ngFor="let note of reportPrintService.report.handling?.reassign_notes" class="note-container">
                  <h3 class="note-header">
                    {{ note.created_at | date:'dd-MM-yy HH:mm' }}
                    <ng-container *ngIf="note.user">{{ note.user.name }}</ng-container>
                    <ng-container *ngIf="!note.user">Onbekend</ng-container>
                  </h3>
                  <div [innerHTML]="note.notes" class="note-content"></div>
                </div>
              </td>
            </tr>
            <tr *ngIf="reportPrintService.report?.handling?.resolved_notes?.length">
              <th>Notitie voltooide melding:</th>
              <td>
                <div *ngFor="let note of reportPrintService.report.handling?.resolved_notes" class="note-container">
                  <h3 class="note-header">
                    {{ note.created_at | date:'dd-MM-yy HH:mm' }}
                    <ng-container *ngIf="note.user">{{ note.user.name }}</ng-container>
                    <ng-container *ngIf="!note.user">Onbekend</ng-container>
                  </h3>
                  <div [innerHTML]="note.notes" class="note-content"></div>
                </div>
              </td>
            </tr>
            <tr *ngIf="reportPrintService.report?.handling?.rejected_notes?.length">
              <th>Notitie afgewezen melding:</th>
              <td>
                <div *ngFor="let note of reportPrintService.report.handling?.rejected_notes" class="note-container">
                  <h3 class="note-header">
                    {{ note.created_at | date:'dd-MM-yy HH:mm' }}
                    <ng-container *ngIf="note.user">{{ note.user.name }}</ng-container>
                    <ng-container *ngIf="!note.user">Onbekend</ng-container>
                  </h3>
                  <div [innerHTML]="note.notes" class="note-content"></div>
                </div>
              </td>
            </tr>
          </table>
          <table style="width:100%">
            <tr>
              <th>Bijlagen:</th>
              <td>
                <ng-container *ngIf="!reportPrintService.report.handling?.attachments?.total">
                  Geen bijlagen
                </ng-container>
                <ng-container *ngIf="reportPrintService.report.handling?.attachments?.total">
                  <div *ngIf="reportPrintService.report.handling?.attachments.media.length" class="media">
                    <div *ngFor="let photo of reportPrintService.report.handling?.attachments.media; let i = index"
                         class="media-div">
                      <img *ngIf="photo.type === 'image'" [src]="photo.thumb" alt="">
                      <video *ngIf="photo.type === 'video'" [src]="photo.src"></video>
                    </div>
                    <div *ngIf="reportPrintService.report.handling?.attachments.documents.length">
                      <ng-container *ngFor="let document of reportPrintService.report.handling?.attachments.documents">
                        <ng-container *ngIf="document.type === 'document'">
                          {{ document.original_filename }}<br>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>
