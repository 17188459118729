import { Injectable } from '@angular/core';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { MenuReportCounts } from '../../interfaces/menu-report-counts';
import { WasteOrder } from '../../interfaces/waste-order/waste-order';
import { WasteOrderTypeService } from '../configuration/waste-order-type.service';
import { WasteOrderType } from 'src/app/interfaces/configuration/waste-order-type';

@Injectable()

export class WasteOrdersService {

  public sourceUpdated: Subject<boolean> = new Subject<boolean>();
  public deletedId: Subject<number> = new Subject<number>();
  public openedId: Subject<number> = new Subject<number>();

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  index(pageType: 'open' | 'closed' | 'all' = 'open'): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get(
        (pageType === 'open'
            ? 'waste-orders.open'
            : (pageType === 'closed'
                ? 'waste-orders.closed'
                : 'waste-orders.all'
            )
        )))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ServerResponse>response);
          } else {
            o.next(false);
          }
        }, (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  count(): Observable<MenuReportCounts | boolean> {
    return new Observable<MenuReportCounts | boolean>((o: Subscriber<MenuReportCounts | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('waste-orders.count'), null, true)
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<MenuReportCounts>response.data);
          } else {
            o.next(false);
          }
        }, (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<WasteOrder | boolean> {
    return new Observable<WasteOrder | boolean>((o: Subscriber<WasteOrder | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('waste-orders.read', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<WasteOrder>response.data);
          } else {
            o.next(false);
          }
        }, (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('waste-orders.create'), formData);
  }

  update(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('waste-orders.update', {':id': id}), formData);
  }

  delete(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('waste-orders.delete', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('waste-orders.restore', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  updateActualQuantity(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('waste-orders.update-actual-quantity', {':id': id}), formData);
  }

  updateActualQuantities(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('waste-orders.update-actual-quantities'), formData);
  }

  history(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('waste-orders.history', {':id': id}));
  }

  export(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('waste-orders.export'));
  }

  resendOpenConfirmation(id: number): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('waste-orders.resend-open-confirmation', {':id': id}));
  }

  getOrderCost(wasteOrderType: WasteOrderType, amount: any): string {
    let cost: number = 0;

    if (!wasteOrderType.cost_per_unit) {
      cost += wasteOrderType.cost;
    } else {
      cost += wasteOrderType.cost + ((amount / wasteOrderType.cost_multiplier) * wasteOrderType.cost_per_unit);
    }

    return Number(parseFloat('' + cost).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2
    }).replace('.', ',');
  }
}
