import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WasteOrderPrintService } from '../../services/waste-orders/waste-order-print.service';
import * as moment from 'moment';

@Component({
  selector: 'fury-waste-order-print',
  templateUrl: './waste-order-print.component.html',
  styleUrls: ['./waste-order-print.component.scss'],
})

export class WasteOrderPrintComponent {

  protected readonly environment = environment;

  public today: string = null;

  constructor(public wasteOrderPrintService: WasteOrderPrintService) {
    this.today = moment().format('MMMM/YY');
  }
}
