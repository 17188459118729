import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LocalStorageService } from '../../../services/base/local-storage.service';
import { Organisation } from '../../../interfaces/routering/organisation';

@Component({
  selector: 'fury-toolbar-darkmode-toggle',
  templateUrl: './toolbar-darkmode-toggle.component.html',
  styleUrls: ['./toolbar-darkmode-toggle.component.scss']
})
export class ToolbarDarkmodeToggleComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
              private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    const darkModeEnabled = this.localStorageService.get('dark-mode');

    if (darkModeEnabled !== null) {
      if (darkModeEnabled) {
        this.toggle();
      }
    }
  }

  toggle(): void {
    const classList: DOMTokenList = this.document.body.classList,
      logo: HTMLImageElement = document.getElementById('logo') as HTMLImageElement,
      organisation: Organisation = this.localStorageService.get('default-organisation');

    if (classList.contains('fury-dark')) {
      classList.remove('fury-dark');
      classList.add('fury-default');
      logo.src = organisation.logo;
      this.localStorageService.delete('dark-mode');
    } else {
      classList.remove('fury-default');
      classList.add('fury-dark');
      logo.src = organisation.logo_darkmode;
      this.localStorageService.set('dark-mode', 1);
    }
  }
}
