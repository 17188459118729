import { Injectable } from '@angular/core';
import { Control, DivIcon, DomUtil, FeatureGroup, latLng, Map, MapOptions, Marker, MarkerOptions, Point, tileLayer, TileLayer } from 'leaflet';
import { Subject } from 'rxjs';
import { Organisation } from '../../interfaces/routering/organisation';
import { AuthenticationService } from './authentication.service';
import Layers = Control.Layers;

@Injectable()

export class LeafletMapService {

  public mapLayerStandard: TileLayer = tileLayer('https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:3857/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: 'Kaartgegevens &copy; <a href="https://www.kadaster.nl">Kadaster</a>',
    minZoom: 6,
    maxZoom: 19,
  });

  public mapLayerSatellite: TileLayer = tileLayer('https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_orthoHR/EPSG:3857/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: 'Kaartgegevens &copy; <a href="https://www.kadaster.nl">Kadaster</a>',
    minZoom: 6,
    maxZoom: 19,
  });

  public mapOptions: MapOptions = {
    layers: [this.mapLayerStandard],
    zoom: 8,
    center: latLng(51.80, 4.94)
  };

  public loadingOptions: any = {
    position: 'topleft',
  };

  public baseMaps = {
    'Achtergrond kaart': this.mapLayerStandard,
    'Luchtfoto kaart': this.mapLayerSatellite
  };

  public legendItems: { color: string; name: string }[] = [];
  public legend: Control = null;

  public markerLayer: FeatureGroup = new FeatureGroup();
  public mapContainer: Map = null;
  public singleMarker: Marker = null;

  private defaultZoomed: number = 17;

  private initView = {
    lat: null,
    lng: null,
    zoom: null
  };

  public mapReady: Subject<null> = new Subject<null>();
  public zoomChanged: Subject<number> = new Subject<number>();

  constructor(private authenticationService: AuthenticationService) {
  }

  init(mapContainer: Map): void {
    this.mapContainer = mapContainer;
    this.mapContainer.addLayer(this.markerLayer);
    this.mapContainer.addControl(new Layers(this.baseMaps));

    // on initial app load with map view open, map center is off, reset it
    setTimeout(() => this.mapContainer.invalidateSize(), 100);

    this.mapContainer.on('zoomend', (): void => {
      this.zoomChanged.next(this.mapContainer.getZoom());
    });

    this.mapReady.next();

    this.authenticationService.organisation$.subscribe((organisation: Organisation): void => {
      if (organisation) {
        this.initView = {
          lat: organisation.center_lat,
          lng: organisation.center_lng,
          zoom: organisation.center_zoom ?? this.defaultZoomed
        };
      }
    });
  }

  setInitialView(organisation: Organisation = null): void {
    if (this.mapContainer !== null) {
      if (
        organisation !== null &&
        typeof organisation.center_lat !== 'undefined' &&
        typeof organisation.center_lng !== 'undefined' &&
        organisation.center_lat !== null &&
        organisation.center_lng !== null
      ) {
        if (typeof organisation.center_zoom !== 'undefined' && organisation.center_zoom !== null) {
          this.mapContainer.setView([organisation.center_lat, organisation.center_lng], organisation.center_zoom);
        } else {
          this.mapContainer.setView([organisation.center_lat, organisation.center_lng]);
        }
      } else {
        this.mapContainer.setView([this.initView.lat, this.initView.lng], this.initView.zoom);
      }
    }
  }

  marker(iconColor: string = null): MarkerOptions {
    return {
      icon: new DivIcon({
        html: '<div class="leaflet-custom-marker-pin"' + (iconColor ? 'style="color:' + iconColor + '"' : '') + '></div>',
        iconSize: [24, 36],
        iconAnchor: [12, 36],
      }),
      draggable: false,
    };
  }

  zoomToMarkers(): void {
    this.mapContainer.fitBounds(this.markerLayer.getBounds(), {padding: new Point(10, 10)});
  }

  updateMarker(coords: [number, number], zoom: number = null): void {
    if (this.singleMarker) {
      this.singleMarker.setLatLng({lat: coords[0], lng: coords[1]});
    } else {
      this.singleMarker = new Marker({lat: coords[0], lng: coords[1]}, this.marker('single'));
      this.markerLayer.addLayer(this.singleMarker);
    }

    if (this.mapContainer !== null) {
      this.mapContainer.setView(coords, zoom ?? this.defaultZoomed);
    }
  }

  addLegend(): void {
    if (this.legendItems.length) {
      this.legend = new Control({position: 'bottomright'});
      this.legend.onAdd = () => {
        const div: HTMLDivElement = DomUtil.create('div', 'info legend');

        div.innerHTML += '<div class="header"><strong>Legenda</strong><span class="legend-toggle">↑↓</span></div>';

        let content: string = '';
        this.legendItems.forEach(d => {
          content += '<div><span style="background:' + d.color + '"></span>' + d.name + '</div>';
        });

        div.innerHTML += '<div class="content">' + content + '</div>';

        div.querySelector('.legend-toggle').addEventListener('click', (): void => {
          div.querySelector('.leaflet-control .content').classList.toggle('collapsed');
        });

        return div;
      };

      if (this.mapContainer !== null) {
        this.legend.addTo(this.mapContainer);
      }
    }
  }

  clear(): void {
    this.markerLayer.clearLayers();
    this.singleMarker = null;
    if (this.legend !== null) {
      this.mapContainer.removeControl(this.legend);
    }
    if (document.querySelector('.legend-toggle') !== null) {
      document.querySelector('.legend-toggle').removeAllListeners('click');
    }
/*
// removed because got an "map being reused" error when reopening editor
    if (this.mapContainer !== null) {
      this.mapContainer.off();
      this.mapContainer.remove();
      this.mapContainer = null;
    }
*/
  }
}
