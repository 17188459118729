<div class="sidenav" fxLayout="column">
  <div [routerLink]="['/']" class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="center center">
    <div>
      <img *ngIf="(authenticationService.organisation$ | async)"
           alt="{{ (authenticationService.organisation$ | async).display_name }}"
           class="sidenav-toolbar-logo"
           src="{{ (authenticationService.organisation$ | async).logo }}"
           id="logo"
      >
      <img *ngIf="!(authenticationService.organisation$ | async)"
           alt="Straatbeeld Online"
           class="sidenav-toolbar-logo"
           src="/assets/img/logo.png"
           id="logo"
      >
    </div>

    <span fxFlex><!-- fill space --></span>
  </div>

  <fury-scrollbar class="sidenav-items" fxFlex="auto">
    <fury-sidenav-item *ngFor="let item of items$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </fury-scrollbar>
  <div class="sidenav-container" fxLayout="column">
    <div class="app-version">Versie {{ version }}</div>
    <fury-sidenav-item *ngFor="let item of itemsBottom$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </div>
</div>
