import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { MonthlyReport } from '../../interfaces/statistics/monthly-report';
import { SourcesReport } from '../../interfaces/statistics/sources-report';
import { YearlyReport } from '../../interfaces/statistics/yearly-report';
import { MonthlyCategoriesReport } from '../../interfaces/statistics/monthly-categories-report';
import { SurveyReport } from '../../interfaces/statistics/survey-report';
import { CustomReport } from '../../interfaces/statistics/custom-report';
import { Ranklist } from '../../interfaces/statistics/ranklist';

@Injectable()

export class StatisticsService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  monthlyReport(): Observable<MonthlyReport[] | boolean> {
    return new Observable<MonthlyReport[] | boolean>((o: Subscriber<MonthlyReport[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.monthly-report'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<MonthlyReport[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  monthlyReportExport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.monthly-report.export'));
  }

  yearlyReport(): Observable<YearlyReport | boolean> {
    return new Observable<YearlyReport | boolean>((o: Subscriber<YearlyReport | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.yearly-report'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<YearlyReport>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  yearlyReportExport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.yearly-report.export'));
  }

  yearlyCommunicationReport(): Observable<YearlyReport | boolean> {
    return new Observable<YearlyReport | boolean>((o: Subscriber<YearlyReport | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.yearly-communication-report'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<YearlyReport>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  yearlyCommunicationReportExport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.yearly-communication-report.export'));
  }

  sourcesReport(): Observable<SourcesReport | boolean> {
    return new Observable<SourcesReport | boolean>((o: Subscriber<SourcesReport | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.sources-report'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<SourcesReport>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  sourcesReportExport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.sources-report.export'));
  }

  monthlyCategoriesReport(): Observable<MonthlyCategoriesReport[] | boolean> {
    return new Observable<MonthlyCategoriesReport[] | boolean>((o: Subscriber<MonthlyCategoriesReport[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.monthly-categories-report'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<MonthlyCategoriesReport[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  monthlyCategoriesReportExport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.monthly-categories-report.export'));
  }

  customCompiledReport(): Observable<CustomReport | boolean> {
    return new Observable<CustomReport | boolean>((o: Subscriber<CustomReport | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.custom-compiled-report'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<CustomReport>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  customCompiledReportExport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.custom-compiled-report.export'));
  }

  surveyReport(): Observable<SurveyReport | boolean> {
    return new Observable<SurveyReport | boolean>((o: Subscriber<SurveyReport | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.survey-report'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<SurveyReport>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  surveyReportExport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.survey-report.export'));
  }

  ranklist(): Observable<Ranklist[] | boolean> {
    return new Observable<Ranklist[] | boolean>((o: Subscriber<Ranklist[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.ranklist'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Ranklist[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  ranklistExport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.ranklist.export'));
  }
}
