import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { Reporter } from '../../interfaces/reporters/reporter';

@Injectable()

export class ReporterService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(): Observable<Reporter[] | boolean> {
    return new Observable<Reporter[] | boolean>((o: Subscriber<Reporter[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reporters.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Reporter[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<Reporter | boolean> {
    return new Observable<Reporter | boolean>((o: Subscriber<Reporter | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reporters.read', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Reporter>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reporters.create'), formData);
  }

  update(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('reporters.update', {':id': id}), formData);
  }

  merge(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reporters.merge'), formData);
  }

  massDelete(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reporters.mass-delete'), formData);
  }

  delete(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('reporters.delete', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('reporters.restore', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  search(): Observable<ServerResponse> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reporters.search'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response !== 'undefined') {
            o.next(<ServerResponse>response);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  quickSearch(value): Observable<ServerResponse> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('reporters.search'), value)
        .subscribe((response: ServerResponse): void => {
          if (typeof response !== 'undefined') {
            o.next(<ServerResponse>response);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }
}
