import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { version } from './environments/version';

if (environment.production || environment.appVersionAddon === '-staging') {
  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.sentry.environment,
    release: version + environment.appVersionAddon,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
      }),
    ],
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.'
    ],
    // Performance Monitoring
    tracesSampleRate: 0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
/*
  .then(moduleInstance => {
    if (window['ngRef']) {
      window['ngRef'].destroy();
    }
    window['ngRef'] = moduleInstance;

    const ngZone = moduleInstance.injector.get(NgZone);
    ngZone.runOutsideAngular(() => {
      setInterval(() => {
        const taskTrackingZone = (<any>ngZone)._inner.getZoneWith('TaskTrackingZone');
        if (!taskTrackingZone) {
          throw new Error('TaskTrackingZone zone not found! Have you loaded node_modules/zone.js/dist/task-tracking.js?');
        }
        let tasks: any[] = taskTrackingZone._properties.TaskTrackingZone.getTasksFor('macroTask');
        tasks = _.clone(tasks);
        if (_.size(tasks) > 0) {
          console.log('ZONE pending tasks=', tasks);
        }
      }, 1000);
    })

  })
*/
  .catch(err => console.log(err));
