import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatetimeAdapter, MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { MomentDatetimeAdapter } from '@ng-matero/extensions-moment-adapter';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YY'
  },
  display: {
    dateInput: 'DD-MM-YY',
    monthLabel: 'MMMM',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'MMMM',
    monthYearA11yLabel: 'YYYY'
  }
};

@NgModule({
  imports: [CommonModule],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    {
      provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
    },
    {
      provide: DatetimeAdapter,
      useClass: MomentDatetimeAdapter,
    },
    {provide: DateAdapter, useClass: MomentDateAdapter},
    {
      provide: MTX_DATETIME_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD-MM-YY',
          monthInput: 'MMMM',
          yearInput: 'YYYY',
          timeInput: 'HH:mm',
          datetimeInput: 'DD-MM-YY HH:mm',
        },
        display: {
          dateInput: 'DD-MM-YY',
          monthInput: 'MMMM',
          yearInput: 'YYYY',
          timeInput: 'HH:mm',
          datetimeInput: 'DD-MM-YY HH:mm',
          monthYearLabel: 'YYYY MMMM',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
          popupHeaderDateLabel: 'MMM DD, ddd',
        },
      },
    },
  ],
})
export class FurySharedDateModule {
  static forRoot() {
    return {
      ngModule: FurySharedDateModule,
      providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
        {
          provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
        },
        {
          provide: DatetimeAdapter,
          useClass: MomentDatetimeAdapter,
        },
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {
          provide: MTX_DATETIME_FORMATS,
          useValue: {
            parse: {
              dateInput: 'DD-MM-YY',
              monthInput: 'MMMM',
              yearInput: 'YYYY',
              timeInput: 'HH:mm',
              datetimeInput: 'DD-MM-YY HH:mm',
            },
            display: {
              dateInput: 'DD-MM-YY',
              monthInput: 'MMMM',
              yearInput: 'YYYY',
              timeInput: 'HH:mm',
              datetimeInput: 'DD-MM-YY HH:mm',
              monthYearLabel: 'YYYY MMMM',
              dateA11yLabel: 'LL',
              monthYearA11yLabel: 'MMMM YYYY',
              popupHeaderDateLabel: 'MMM DD, ddd',
            },
          },
        },
      ]
    };
  }
}
