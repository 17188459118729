import { Component, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';
import { AuthenticationService } from '../../services/base/authentication.service';
import { version } from '../../../environments/version';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Organisation } from '../../interfaces/routering/organisation';

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  public version: string = version + environment.appVersionAddon;
  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  items$: Observable<SidenavItem[]>;
  itemsBottom$: Observable<SidenavItem[]>;

  constructor(@Inject(DOCUMENT) private document: Document,
              public authenticationService: AuthenticationService,
              private sidenavService: SidenavService,
  ) {
  }

  ngOnInit(): void {
    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );

    this.itemsBottom$ = this.sidenavService.itemsBottom$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );

    this.authenticationService.organisation$.subscribe((organisation: Organisation): void => {
      const classList: DOMTokenList = this.document.body.classList,
        logo: HTMLImageElement = document.getElementById('logo') as HTMLImageElement;

      if (logo !== null) {
        if (classList.contains('fury-default')) {
          logo.src = (organisation !== null && typeof organisation.logo !== 'undefined' && organisation.logo !== ''
              ? organisation.logo
              : 'assets/img/logo.svg'
          );
        } else {
          logo.src = 'assets/img/logo-darkmode.png';
        }
      }
    });
  }

  ngOnDestroy(): void {
  }
}
