import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../../interfaces/routering/user';
import { Organisation } from '../../interfaces/routering/organisation';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from './api-endpoints.service';
import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { WebSocketPermissions } from '../../interfaces/web-socket-permissions';
import { SnackbarService } from './snackbar.service';
import * as Sentry from '@sentry/browser';

@Injectable()

export class AuthenticationService {
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public user$: Observable<User> = this._user as Observable<User>;

  private _organisation: BehaviorSubject<Organisation> = new BehaviorSubject<Organisation>(null);
  public organisation$: Observable<Organisation> = this._organisation as Observable<Organisation>;

  private _eventReceived: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public eventReceived$: Observable<boolean> = this._eventReceived as Observable<boolean>;

  private channelsWithEvents: any = [];
  private pusherEvents: string[] = [
    '.notifications',
    '.report-counts',
  ];

  public returnUrl: string = '/';

  constructor(private api: ApiService,
              private httpBackend: HttpBackend,
              private apiEndpoints: ApiEndpointsService,
              private router: Router,
              private localStorageService: LocalStorageService,
              private snackbarService: SnackbarService,
  ) {
    this._organisation.next(this.localStorageService.get('organisation'));
    this._user.next(this.localStorageService.get('user'));
    this.getOrganisationConfig();

    this.getUserAccount();

    this.organisation$.subscribe((organisation: Organisation): void => {
      this.updateAppColors(organisation);
    });

    this.user$.subscribe((user: User): void => {
      if (user) {
        Sentry.setUser({
          id: user.id,
          username: user.name,
          email: user.email,
        });

        let i: number = 0;
        const interval: NodeJS.Timeout = setInterval((): void => {
          i++;
          if (typeof window.Echo !== 'undefined') {
            clearInterval(interval);

            // clear old subscriptions, required when impersonating user
            if (this.channelsWithEvents.length) {
              this.channelsWithEvents.forEach((event: string): void => {
                try {
                  window.Echo.private('user.' + user.id).stopListening(event);
                } catch (e) {
                }
              });
              this.channelsWithEvents = [];
            }

            // create new subscriptions
            this.pusherEvents.forEach((event: string): void => {
              if (this.channelsWithEvents.findIndex(d => d.channel === 'user.' + user.id && d.events.event) === false) {
                if (!environment.production) {
                  console.log('WebSocket startSubscription: ', {channel: 'user.' + user.id, event: event});
                }
                this.channelsWithEvents.push({
                  channel: 'user.' + user.id,
                  events: this.pusherEvents
                });
                window.Echo.private('user.' + user.id).listen(event, (event2: {
                  status: string,
                  message: string,
                }): void => {
                  if (!environment.production) {
                    console.log('WebSocket received event for user.' + user.id + ' channel: ' + event2);
                  }
                  if (event === '.notifications') {
                    if (event2.status) {
                      this.snackbarService[event2.status](event2.message);
                    } else {
                      this.snackbarService.info(event2.message);
                    }
                  }
                });
              }
            });
          }

          if (i > 100) {
            clearInterval(interval);
          }
        }, 500);
      } else {
        Sentry.setUser(null);
        if (typeof window.Echo !== 'undefined') {
          this.channelsWithEvents.forEach((subscription: any): void => {
            if (!environment.production) {
              console.log('WebSocket stopSubscription: ', subscription);
            }
            subscription.events.forEach((event: string): void => {
              window.Echo.private(subscription.channel).stopListening(event);
            });
          });
        }
      }
    });
  }

  updateOrganisation(organisation: Organisation): void {
    this.localStorageService.set('organisation', organisation);
    this._organisation.next(organisation);
  }

  updateAppColors(organisation: Organisation = null): void {
    document.documentElement.style.setProperty('--menu-background-color',
      (organisation !== null && typeof organisation.color_menu_background !== 'undefined' && organisation.color_menu_background !== ''
          ? organisation.color_menu_background
          : '#005192'
      )
    );
    document.documentElement.style.setProperty('--menu-text-color',
      (organisation !== null && typeof organisation.color_menu_text !== 'undefined' && organisation.color_menu_text !== ''
          ? organisation.color_menu_text
          : '#ffffff'
      )
    );
    document.documentElement.style.setProperty('--primary-color',
      (organisation !== null && typeof organisation.color_primary_action !== 'undefined' && organisation.color_primary_action !== ''
          ? organisation.color_primary_action
          : '#1b143c'
      )
    );
    document.documentElement.style.setProperty('--primary-color-text',
      (organisation !== null && typeof organisation.color_primary_action_text !== 'undefined' && organisation.color_primary_action_text !== ''
          ? organisation.color_primary_action_text
          : '#ffffff'
      )
    );
    document.documentElement.style.setProperty('--secondary-color',
      (organisation !== null && typeof organisation.color_secondary_action !== 'undefined' && organisation.color_secondary_action !== ''
          ? organisation.color_secondary_action
          : '#666666'
      )
    );
    document.documentElement.style.setProperty('--secondary-color-text',
      (organisation !== null && typeof organisation.color_secondary_action_text !== 'undefined' && organisation.color_secondary_action_text !== ''
          ? organisation.color_secondary_action_text
          : '#ffffff'
      )
    );
  }

  getOrganisationConfig(): void {
    const defaultOrganisation = this.localStorageService.get('default-organisation'),
      organisation = this.localStorageService.get('organisation');

    if (organisation === null) {
      this._organisation.next(<Organisation>defaultOrganisation);
      this.getDefaultOrganisation().then((data: Organisation | null): void => {
        this._organisation.next(data);
      });
    } else if (organisation) {
      this._organisation.next(<Organisation>organisation);
    } else {
      this._organisation.next(null);
    }
  }

  getDefaultOrganisation(): Promise<Organisation | null> {
    const httpApi: HttpClient = new HttpClient(this.httpBackend);
    return httpApi.get(environment.api_endpoint + this.apiEndpoints.get('public.default-organisation'), {
      observe: 'response',
      responseType: 'json',
    })
      .toPromise()
      .then(
        (response: HttpResponse<ServerResponse>): Organisation => {
          if (typeof response.body.data !== 'undefined') {
            this.localStorageService.set('default-organisation', response.body.data);
            return response.body.data;
          }

          return null;
        }
      )
      .catch((): null => null);
  }

  login(formData: any): Promise<boolean> {
    return this.api.post(this.apiEndpoints.get('auth.login'), formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);

            if (typeof response.data.organisation !== 'undefined') {
              this.localStorageService.set('default-organisation', response.data.organisation);
              this._organisation.next(response.data.organisation);
            }

            this.router.navigateByUrl(this.returnUrl).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  logout(): Subscription {
    return this.api.get(this.apiEndpoints.get('auth.logout')).subscribe((): void => {
      this.silentLogout();
    });
  }

  silentLogout(): void {
    this.localStorageService.delete('api-token');
    this.localStorageService.delete('user');
    this.localStorageService.delete('organisation');

    this._user.next(null);
    this._organisation.next(null);

    this.router.navigate(['/auth/login']).then((): boolean => true);
  }

  validateCompleteAccountToken(id: number, expires: string, signature: string): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('auth.complete-account', {':id': id, ':expires': expires, ':signature': signature}), null, true);
  }

  completeAccount(id: number, expires: string, signature: string, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('auth.complete-account', {':id': id, ':expires': expires, ':signature': signature}), formData);
  }

  forgotPassword(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('auth.forgot-password'), formData);
  }

  validateResetPasswordToken(token: string, email: string): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('auth.reset-password.validate', {':token': token, ':email': email}), null, true);
  }

  resetPassword(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('auth.reset-password'), formData);
  }

  changePassword(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('auth.change-password'), formData);
  }

  getAccount(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('auth.account'), null, true);
  }

  getUserAccount(): void {
    setTimeout((): void => {
      this.api.get(this.apiEndpoints.get('auth.account'), null, true).subscribe((response: ServerResponse): void => {
        if (typeof response.data !== 'undefined') {
          this.localStorageService.set('user', response.data);
          this._user.next(response.data);
        }
      });
    }, 0);
  }

  updateAccount(formData: any): Promise<boolean> {
    return this.api.post(this.apiEndpoints.get('auth.account'), formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          this.localStorageService.set('user', response.data);
          this._user.next(response.data);

          this.router.navigate(['/']).then((): void => {
          });

          return true;
        } else {
          return false;
        }
      })
      .catch((): boolean => false);
  }

  startImpersonating(formData): Promise<boolean> {
    return this.api.post(this.apiEndpoints.get('auth.impersonate'), formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);

            this.router.navigate(['/']).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  stopImpersonating(): Promise<boolean> {
    return this.api.delete(this.apiEndpoints.get('auth.impersonate'), false).toPromise()
      .then
      ((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);

            this.router.navigate(['/routering/gebruikers']).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  resendInvitation(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('routering.users.resend-invitation', {':id': id}));
  }

  startChannelSubscription(webSocket: WebSocketPermissions): void {
    this.user$.pipe(first()).subscribe((user: User): void => {
      if (user) {
        if (typeof window.Echo !== 'undefined') {
          if (!environment.production) {
            console.log('WebSocket startSubscription config: ', webSocket);
          }
          if (user.role === 'super-admin' || user.permissions.some((permission: string) => webSocket.permissions.includes(permission))) {
            webSocket.events.forEach((event: string): void => {
              window.Echo.private(webSocket.channel).listen(event, (): void => {
                if (!environment.production) {
                  console.log('WebSocket received event for "' + webSocket.channel + '" channel: ' + event);
                }
                this._eventReceived.next(true);
              });
            });
          }
        }
      }
    });
  }

  stopChannelSubscription(webSocket: WebSocketPermissions): void {
    this.user$.pipe(first()).subscribe((user: User): void => {
      if (user) {
        if (typeof window.Echo !== 'undefined') {
          if (!environment.production) {
            console.log('WebSocket stopSubscription config: ', webSocket);
          }
          if (user.role === 'super-admin' || user.permissions.some((permission: string) => webSocket.permissions.includes(permission))) {
            webSocket.events.forEach((event: string): void => {
              window.Echo.private(webSocket.channel).stopListening(event);
            });
          }
        }
      }
    });
  }
}
