<!--suppress AngularUndefinedTag -->
<ng-container *ngIf="wasteOrderPrintService.wasteOrder?.length > 1">
  <h1>Afroeporder Gemeente Drimmelen {{ today }}</h1>
  <ng-container *ngFor="let order of wasteOrderPrintService.wasteOrder">
    <table class="table table-hover table-condensed table-bordered" style="border-collapse: collapse;page-break-inside: avoid;">
      <tbody>
      <tr>
        <th style="width:120px;vertical-align:top;text-align:left;border-right:1px solid #ddd;border-bottom:1px solid #ddd;">{{order.type.display_name}}</th>
        <td style="vertical-align:top;text-align:left;border-left:0;border-right:1px solid #ddd;border-bottom:1px solid #ddd;">{{ order.pickup.location }}</td>
        <th style="vertical-align:top;text-align:left;border-left:0;border-right:1px solid #ddd;border-bottom:1px solid #ddd;">Naam: <span style="font-weight: 400;">{{ order.contact.firstname }} {{ order.contact.lastname }}</span></th>
        <th style="vertical-align:top;text-align:left;border-left:0;border-bottom:1px solid #ddd;">Telefoon: <span style="font-weight: 400;">{{ order.contact.phone }}</span></th>
      </tr>
      <tr>
        <th style="vertical-align:top;text-align:left;border-right:1px solid #ddd;border-bottom:0;border-top:0;">Hoeveelheid opgehaald</th>
        <td style="vertical-align:middle;text-align:right;border:0;border-right:1px solid #ddd;"> {{ order.type.quantity_text }}</td>
        <th style="vertical-align:top;text-align:left;border:0;border-right:1px solid #ddd;">Ophaaldatum</th>
        <td style="vertical-align:top;text-align:left;border-left:0;border-bottom:0;border-top:0;"> {{ order?.pickup.actual_pickup_at | date:'d-M-YY HH:mm'}}</td>
      </tr>
      <tr>
        <th colspan="2" style="width:50%;vertical-align:top;text-align:left;border-right:1px solid #ddd;border-top:1px solid #ddd;">
          Interne notitie:
          <ng-container *ngFor="let note of order.internal_notes">
            <p style="font-weight:bold;font-style:italic;margin:0;">
              {{ note.created_at | date:'d-M-YY HH:mm' }}
              /
              <ng-container *ngIf="note.user">{{ note.user.name }}</ng-container>
              <ng-container *ngIf="!note.user">Onbekend</ng-container>
            </p>
            <p style="font-weight:normal;margin-top:0;">{{ note.notes }}</p>
          </ng-container>
        </th>
        <th colspan="2" style="width:50%;vertical-align:top;text-align:left;border-left:0;border-top:1px solid #ddd;">Notitie: <span style="font-weight: 400;">{{ order.notes }}</span></th>
      </tr>
      </tbody>
    </table>
    <br>
    <br>
  </ng-container>
</ng-container>
<ng-container *ngIf="wasteOrderPrintService.wasteOrder?.length === 1">
  <table class="print-container">
    <thead class="print-header">
    <tr>
      <th>
        <h1>Overzicht afroeporder</h1>
      </th>
    </tr>
    </thead>
    <tbody class="print-content">
    <tr>
      <td>
        <div class="content">
          <h2>Afroeporder gegevens</h2>
          <table style="width:100%">
            <tr>
              <th>Type:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].type?.display_name }}</td>
            </tr>
            <tr>
              <th>Datum & tijd:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].created_at | date:'d-M-y H:i' }}</td>
            </tr>
            <tr>
              <th>Ophaallocatie:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].pickup.location }}</td>
            </tr>
            <tr>
              <th>Geschatte aantal:</th>
              <td>
                {{ wasteOrderPrintService.wasteOrder[0].pickup.estimated_amount }}
                <ng-container *ngIf="wasteOrderPrintService.wasteOrder[0].pickup.estimated_amount">
                  {{ wasteOrderPrintService.wasteOrder[0].type?.quantity_text }}
                </ng-container>
              </td>
            </tr>
            <tr>
              <th>Geschatte ophaaldatum:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].pickup.estimated_pickup_at | date:'d-M-y H:i' }}</td>
            </tr>
            <tr>
              <th>Hoeveelheid:</th>
              <td>
                {{ wasteOrderPrintService.wasteOrder[0].pickup.actual_amount }}
                <ng-container *ngIf="wasteOrderPrintService.wasteOrder[0].pickup.actual_amount">
                  {{ wasteOrderPrintService.wasteOrder[0].type?.quantity_text }}
                </ng-container>
              </td>
            </tr>
            <tr>
              <th>Ophaaldatum:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].pickup.actual_pickup_at | date:'d-M-y H:i' }}</td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Contactgegevens</h2>
          <table style="width:100%">
            <tr>
              <th>Bedrijfsnaam:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].contact.company_name }}</td>
            </tr>
            <tr>
              <th>Voorletters:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].contact.initials }}</td>
            </tr>
            <tr>
              <th>Voornaam:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].contact.firstname }}</td>
            </tr>
            <tr>
              <th>Achternaam:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].contact.lastname }}</td>
            </tr>
            <tr>
              <th>Adres:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].contact.location }}</td>
            </tr>
            <tr>
              <th>Telefoonnummer:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].contact.phone }}</td>
            </tr>
            <tr>
              <th>E-mail:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].contact.email }}</td>
            </tr>
          </table>
        </div>

        <div class="content" *ngIf="wasteOrderPrintService.wasteOrder[0].invoice?.details">
          <h2>Factuur gegevens</h2>
          <table style="width:100%">
            <tr>
              <th>Bedrijfsnaam:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].invoice.company_name }}</td>
            </tr>
            <tr>
              <th>Voorletters:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].invoice.initials }}</td>
            </tr>
            <tr>
              <th>Voornaam:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].invoice.firstname }}</td>
            </tr>
            <tr>
              <th>Achternaam:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].invoice.lastname }}</td>
            </tr>
            <tr>
              <th>Adres:</th>
              <td>{{ wasteOrderPrintService.wasteOrder[0].invoice.location }}</td>
            </tr>
          </table>
        </div>

        <div class="content" *ngIf="wasteOrderPrintService.wasteOrder[0].internal_notes.length">
          <h2>Interne notities</h2>
          <div *ngFor="let note of wasteOrderPrintService.wasteOrder[0].internal_notes" class="note-container">
            <h3 class="note-header">
              {{ note.created_at | date:'dd-MM-yy HH:mm' }}
              <ng-container *ngIf="note.user">{{ note.user.name }}</ng-container>
              <ng-container *ngIf="!note.user">Onbekend</ng-container>
            </h3>
            <div [innerHTML]="note.notes" class="note-content"></div>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>
