import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AppUpdateDialogComponent } from './app-update-dialog/app-update-dialog.component';
import { LocationSelectorDialogComponent } from './location-selector/location-selector-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxLoadingControlModule } from '@runette/ngx-leaflet-loading';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LocationViewerDialogComponent } from './location-viewer/location-viewer-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FuryAlertBoxModule } from '../alert-box/alert-box.module';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingOverlayModule } from '../loading-overlay/loading-overlay.module';
import { EmailViewerDialogComponent } from './email-viewer/email-viewer-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuillModule } from 'ngx-quill';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { SeedDialogComponent } from './seed-dialog/seed-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DeleteWithNoteDialogComponent } from './delete-with-note-dialog/delete-with-note-dialog.component';
import { SwitchOrganisationDialogComponent } from './switch-organisation-dialog/switch-organisation-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    LeafletModule,
    NgxLoadingControlModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    FuryAlertBoxModule,
    MatIconModule,
    FlexLayoutModule,
    LoadingOverlayModule,
    MatDividerModule,
    MatMenuModule,
    MatTooltipModule,
    QuillModule,
    ScrollbarModule,
    MatCheckboxModule
  ],
  declarations: [
    DeleteDialogComponent,
    DeleteWithNoteDialogComponent,
    AppUpdateDialogComponent,
    LocationSelectorDialogComponent,
    LocationViewerDialogComponent,
    EmailViewerDialogComponent,
    ExportDialogComponent,
    SeedDialogComponent,
    SwitchOrganisationDialogComponent,
  ],
  exports: [
    DeleteDialogComponent,
    DeleteWithNoteDialogComponent,
    AppUpdateDialogComponent,
    LocationSelectorDialogComponent,
    LocationViewerDialogComponent,
    EmailViewerDialogComponent,
    ExportDialogComponent,
    SeedDialogComponent,
    SwitchOrganisationDialogComponent,
  ]
})

export class DialogsModule {
}
