import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { SidenavService } from './sidenav/sidenav.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@fury/utils/check-router-childs-data';
import { ReportPanelService } from '../services/reports/report-panel.service';
import { BackdropDirective } from '../../@fury/shared/backdrop/backdrop.directive';
import { ReportPrintService } from '../services/reports/report-print.service';
import { LayoutService } from './layout.service';
import { environment } from '../../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { ApiService } from '../services/base/api.service';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { WasteOrderPanelService } from '../services/waste-orders/waste-order-panel.service';
import { WasteOrderPrintService } from '../services/waste-orders/waste-order-print.service';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild('backdrop') backdrop: BackdropDirective;
  @ViewChild('reportPanelPlaceholder', {read: ViewContainerRef}) reportPanelPlaceholder: ViewContainerRef;
  @ViewChild('wasteOrderPanelPlaceholder', {read: ViewContainerRef}) wasteOrderPanelPlaceholder: ViewContainerRef;

  @ViewChild('scrollBar', {read: InfiniteScrollDirective}) set scrollBar(scrollBar: InfiniteScrollDirective) {
    this.layoutService.scrollbar = scrollBar;
  }

  @ViewChild('scrollBar', {read: ElementRef}) set scrollBarRef(scrollBar: ElementRef) {
    this.layoutService.scrollbarRef = scrollBar;
  }

  sidenavOpen$: Observable<boolean> = this.sidenavService.open$;
  sidenavMode$: Observable<'side' | 'over'> = this.sidenavService.mode$;
  sidenavCollapsed$: Observable<boolean> = this.sidenavService.collapsed$;

  scrollDisabled$: Observable<any> = this.router.events.pipe(
    filter<NavigationEnd>((event: NavigationEnd): boolean => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  public badConnection: boolean = false;
  public isStaging: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2,
              private sidenavService: SidenavService,
              private cd: ChangeDetectorRef,
              private router: Router,
              private swUpdate: SwUpdate,
              public api: ApiService,
              public layoutService: LayoutService,
              public reportPanelService: ReportPanelService,
              public reportPrintService: ReportPrintService,
              public wasteOrderPanelService: WasteOrderPanelService,
              public wasteOrderPrintService: WasteOrderPrintService,
              ) {
  }

  ngOnInit(): void {
    this.cd.detectChanges();

    this.swUpdate.unrecoverable.subscribe((): void => {
      this.badConnection = true;
      this.renderer.addClass(this.document.body, 'is-staging');
    });

    if (environment.appVersionAddon === '-staging') {
      this.isStaging = true;
      this.renderer.addClass(this.document.body, 'is-staging');
    }
  }

  closeSidenav(): void {
    this.sidenavService.close();
  }

  openSidenav(): void {
    this.sidenavService.open();
  }

  reloadApp(): void {
    this.layoutService.updateApp.next();
  }

  ngOnDestroy(): void {
  }
}

