import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthorizationService } from './services/base/authorization.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthorizationService],
    canActivateChild: [AuthorizationService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/meldingen'
      },
      {
        path: 'meldingen',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: {
          permissions: [
            'reports.unassigned.assign',
            'reports.handle.bulk',
            'reports.copy',
            'reports.create',
            'reports.delete',
            'reports.closed.edit',
            'reports.forward',
            'reports.print',
            'reports.view.all-organisations',
            'reports.closed.reopen',
            'reports.handle',
            'reports.deleted.read',
            'reports.unassigned.view',
            'reports.update',
            'reports.view',
          ]
        }
      },
      {
        path: 'afroeporders',
        loadChildren: () => import('./pages/waste-orders/waste-order.module').then(m => m.WasteOrderModule),
        data: {
          permissions: [
            'waste-orders.manage',
          ]
        }
      },
      {
        path: 'routering',
        loadChildren: () => import('./pages/routering/routering.module').then(m => m.RouteringModule),
        data: {
          permissions: [
            'routering.organisations.manage',
            'routering.departments.manage',
            'routering.roles.manage',
            'routering.users.manage',
            'routering.categories.manage',
          ]
        }
      },
      {
        path: 'instellingen',
        loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationModule),
        data: {
          permissions: [
            'configuration.email-templates.manage',
            'configuration.standard-reactions.manage',
            'configuration.address-book.manage',
            'configuration.knowledge-base.manage',
            'configuration.faq.manage',
            'configuration.changelogs.manage',
            'configuration.report-statuses.manage',
            'configuration.report-sources.manage',
            'configuration.report-history.manage',
          ]
        }
      },
      {
        path: 'melders',
        loadChildren: () => import('./pages/reporters/reporters.module').then(m => m.ReportersModule),
        data: {
          permissions: [
            'reporters.manage'
          ],
        }
      },
      {
        path: 'handleiding',
        loadChildren: () => import('./pages/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule),
      },
      {
        path: 'kaart-weergave',
        loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule),
        data: {
          permissions: [
            'map.view'
          ]
        }
      },
      {
        path: 'statistics',
        loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
        data: {
          permissions: [
            'statistics.read',
            'statistics.mkc-report.read',
          ]
        }
      },
      {
        path: 'aankondigingen',
        loadChildren: () => import('./pages/public-notifications/public-notifications.module').then(m => m.PublicNotificationsModule),
        data: {
          permissions: [
            'configuration.public-notifications.manage',
          ],
        }
      },
      {
        path: 'afvalkalender',
        loadChildren: () => import('./pages/waste-calendar/waste-calendar.module').then(m => m.WasteCalendarModule),
        data: {
          permissions: [
            'waste-calendar.manage',
          ],
        }
      },
      {
        path: 'archivering',
        loadChildren: () => import('./pages/archive/archive/archive.module').then(m => m.ArchiveModule),
//        loadChildren: () => import('./pages/archive/archive.module').then(m => m.ArchiveModule),
        data: {
          permissions: [
            'archive.manage'
          ],
        }
      },
      {
        path: 'sent-emails',
        loadChildren: () => import('./pages/sent-emails/sent-emails.module').then(m => m.SentEmailsModule),
        data: {
          permissions: [],
        }
      },
      {
        path: 'afval-kalender',
        redirectTo: 'https://drimmelen.afvalkalender.sd-staging.nl',
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/meldingen'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
//    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
