import { Directive, HostBinding, Inject, Input, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BackdropDirective } from '../backdrop/backdrop.directive';

@Directive({
  selector: '[furyWasteOrderPanel],fury-waste-order-panel',
  host: {
    class: 'fury-waste-order-panel'
  },
  exportAs: 'furyWasteOrderPanel'
})
export class WasteOrderPanelDirective implements OnDestroy {

  @Input() backdrop: BackdropDirective;

  constructor(@Inject(DOCUMENT) private document: Document,
  ) {
  }

  private _opened: boolean;

  get opened() {
    return this._opened;
  }

  @Input()
  @HostBinding('class.open')
  set opened(open: boolean) {
    this._opened = open;
    open ? this.showBackdrop() : this.hideBackdrop();
  }

  showBackdrop(): void {
    if (this.backdrop) {
      this.backdrop.show();

      this.enableScrollblock();
    }
  }

  hideBackdrop(): void {
    if (this.backdrop) {
      this.backdrop.hide();
    }

    this.disableScrollblock();
  }

  enableScrollblock(): void {
    this.document.body.classList.add('fury-scrollblock');
  }

  disableScrollblock(): void {
    this.document.body.classList.remove('fury-scrollblock');
  }

  ngOnDestroy(): void {
  }
}
