import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WasteOrderPanelDirective } from './waste-order-panel.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [WasteOrderPanelDirective],
  exports: [WasteOrderPanelDirective]
})
export class WasteOrderPanelModule {
}
