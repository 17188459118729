import { Injectable } from '@angular/core';
import { WasteOrder } from '../../interfaces/waste-order/waste-order';

@Injectable()

export class WasteOrderPrintService {

  public wasteOrder: WasteOrder[] = null;

  constructor() {
  }

  print(): void {
    // noinspection JSUnresolvedLibraryURL
    setTimeout((): void => {
      const myWindow: Window = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
      let htmlHead: string = '<html lang="nl"><head><title>Afroeporder gegevens</title>' +
        '<link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700|Material+Icons+Outlined" rel="stylesheet">' +
        '<link href="https://cdnjs.cloudflare.com/ajax/libs/weather-icons/2.0.12/css/weather-icons.min.css" rel="stylesheet">' +
        '<link rel="apple-touch-icon" sizes="180x180" href="/assets/favicon/apple-touch-icon.png">' +
        '<link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon/favicon-32x32.png">' +
        '<link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon/favicon-16x16.png">' +
        '<link rel="manifest" href="/assets/favicon/site.webmanifest">' +
        '<link rel="mask-icon" href="/assets/favicon/safari-pinned-tab.svg" color="#5bbad5">' +
        '<link rel="shortcut icon" href="/assets/favicon/favicon.ico">' +
        '<meta name="msapplication-TileColor" content="#2b5797">' +
        '<meta name="msapplication-config" content="/assets/favicon/browserconfig.xml">' +
        '<meta name="theme-color" content="#ffffff">' +
        '</head>' +
        '<style>' +
        'body {font-family: Helvetica, sans-serif;font-size: 14px;}';
      if (this.wasteOrder.length > 1) {
        htmlHead += 'h1 {font-size: 15px;}' +
          '.table {table-layout: fixed;width: 100%;max-width: 100%;background-color: #fff;border-spacing: 0;font-size: 14px;border: 0;}' +
          '.table tr > th,.table tr > td {text-align: left;padding: 2px;vertical-align: middle;border: 1px solid #a0a0a0;}';
      } else {
        htmlHead += '.print-container {page-break-after: always;}' +
          '.print-header {display: table-header-group;}' +
          'h1 {font-size: 22px;margin: 0 0 20px 0;text-align:left;}' +
          '.content {-wekbit-column-break-inside: avoid;-moz-column-break-inside: avoid;break-inside: avoid;}' +
          'h2 {margin-bottom:10px;font-size: 20px;border-bottom: 1px solid #808080;padding-bottom: 10px;}' +
          'table {width: 100%;font-size: 14px;table-layout: fixed;border-spacing: 0;border-collapse: separate;}' +
          'table th {text-align: right;width: 200px;padding-right: 10px;padding-bottom: 5px;vertical-align: top;}' +
          'table td {text-align: left;vertical-align: top;}' +
          '.note-container + .note-container {margin-top: 6px;padding-top: 6px;border-top: 1px solid rgba(0, 0, 0, 0.12);}' +
          '.note-container h3 {margin: 0;padding: 0;font-size: 13px;}';
      }
      htmlHead += '</style>' +
        '<body>';
      myWindow.document.write(htmlHead);
      myWindow.document.write(document.getElementsByTagName('fury-waste-order-print')[0].innerHTML);
      myWindow.document.write('</body></html>');

      myWindow.document.close();
      myWindow.focus();

      myWindow.addEventListener('load', (): void => {
        setTimeout((): void => {
          myWindow.print();
          myWindow.close();
        }, 350);
      });
    }, 0);
  }
}
