import { Injectable } from '@angular/core';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { Report } from '../../interfaces/report/report';
import { MenuReportCounts } from '../../interfaces/menu-report-counts';

@Injectable()

export class ReportService {

  public sourceUpdated: Subject<boolean> = new Subject<boolean>();
  public reportDeletedId: Subject<number> = new Subject<number>();
  public reportOpenedId: Subject<number> = new Subject<number>();

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  index(pageType: 'open' | 'review' | 'closed' | 'all' = 'open'): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get(
        (pageType === 'open'
            ? 'reports.index'
            : (pageType === 'closed'
                ? 'reports.closed'
                : (pageType === 'review'
                  ? 'reports.review'
                  : 'reports.overview')
            )
        )))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ServerResponse>response);
          } else {
            o.next(false);
          }
        }, (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  missing(pageType: 'open' | 'review' | 'closed' | 'all' = 'open', start: number = 0): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get(
        (
          (pageType === 'open'
              ? 'reports.index'
              : (pageType === 'closed'
                  ? 'reports.closed'
                  : (pageType === 'review'
                    ? 'reports.review'
                    : 'reports.overview')
              )
          ))), {missing_start: start})
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ServerResponse>response);
          } else {
            o.next(false);
          }
        }, (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  count(): Observable<MenuReportCounts | boolean> {
    return new Observable<MenuReportCounts | boolean>((o: Subscriber<MenuReportCounts | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.count'), null, true)
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<MenuReportCounts>response.data);
          } else {
            o.next(false);
          }
        }, (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<Report | boolean> {
    return new Observable<Report | boolean>((o: Subscriber<Report | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.read', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Report>response.data);
          } else {
            o.next(false);
          }
        }, (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.create'), formData);
  }

  update(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('reports.update', {':id': id}), formData);
  }

  handle(id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('reports.handle', {':id': id}), formData);
  }

  approve(id: number): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.approve', {':id': id}));
  }

  reject(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.reject', {':id': id}), formData);
  }

  delete(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('reports.delete', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              this.reportDeletedId.next(id);
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('reports.restore', {':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  downloadAttachment(id: number, attachment_id: number): void {
    this.api.download(this.apiEndpoints.get('reports.download-attachment', {':id': id, ':attachment_id': attachment_id}));
  }

  downloadHandlingAttachment(id: number, attachment_id: number): void {
    this.api.download(this.apiEndpoints.get('reports.download-handling-attachment', {':id': id, ':attachment_id': attachment_id}));
  }

  deleteAttachment(id: number, attachment_id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('reports.download-attachment', {':id': id, ':attachment_id': attachment_id}))
        .subscribe((response: ServerResponse): void => {
          if (response) {
            o.next(true);
          } else {
            o.next(false);
          }
        }, (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reassign(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.reassign', {':id': id}), formData);
  }

  forward(id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.forward', {':id': id}), formData);
  }

  history(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('reports.history', {':id': id}));
  }

  copy(id: number): Observable<Report> {
    return new Observable<Report>((o: Subscriber<Report>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.copy', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Report>response.data);
          } else {
            o.next(undefined);
          }
        }, (): void => {
          o.next(undefined);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  overviewExport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('reports.overview.export'));
  }

  resendOpenConfirmation(id: number): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.resend-open-confirmation', {':id': id}));
  }

  rotatePhoto(id: number, photoId: number, direction: 'counter-clockwise' | 'clockwise'): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('reports.rotate-photo', {':id': id, ':photoId': photoId, ':direction': direction}));
  }

  handlingDaysRange(handling_days: number, handled_days: number): Array<number> {
    const items: number[] = [0];
    if (handled_days > 0 && handling_days !== null) {
      let p: number,
        p2: number;

      p = (handled_days / handling_days);
      p2 = Math.round((7 * (p > 1 ? 1 : p)));

      for (let i = 1; i < p2; i++) {
        items.push(i);
      }
    }
    return items;
  }

}
