import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()

export class WasteOrderPanelService {

  public editorType: 'create' | 'update' = 'update';

  public nextWasteOrderId: BehaviorSubject<number | boolean> = new BehaviorSubject<number>(null);
  private _openSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  open$: Observable<boolean> = this._openSubject.asObservable().pipe(delay(0));

  public isOpen: boolean = false;

  constructor() {
  }

  open(waste_order_id?: number | string): void {
    this.editorType = waste_order_id ? 'update' : 'create';
    this.nextWasteOrderId.next(waste_order_id ? parseInt(<string>waste_order_id, 10) : null);
    this._openSubject.next(true);
    this.isOpen = true;
  }

  close(): void {
    this._openSubject.next(false);
    this.isOpen = false;
  }
}
