import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ImageHandler, Options, VideoHandler } from 'ngx-quill-upload';
import * as Quill from 'quill';
import { FormBuilder, FormControl } from '@angular/forms';

Quill.register('modules/imageHandler', ImageHandler);
Quill.register('modules/videoHandler', VideoHandler);

@Injectable()

export class QuillService {

  public uploadUrl: string = null;
  public uploadType: string = 'postUpload';

  public uploadError: string = null;

  private form = this.fb.group({});

  public minimal = {
    toolbar: {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'size': ['small', false, 'large']}],
        ['code-block'],

        [{'color': []}, {'background': []}],
        [{'align': []}],

        ['link'],
      ],
    },
    formats: [
      'background',
      'bold',
      'color',
      'code',
      'italic',
      'link',
      'size',
      'underline',
      'indent',
      'list',
      'align',
      'code-block',
    ],
  };
  public basic = {
    toolbar: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['code-block'],

        [{'size': ['small', false, 'large']}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        [{'color': []}, {'background': []}],
        [{'align': []}],

        ['link'],
      ],
    },
    formats: [
      'background',
      'bold',
      'color',
      'code',
      'italic',
      'link',
      'size',
      'strike',
      'underline',
      'header',
      'list',
      'align',
      'code-block',
    ],
  };
  public withImagesAndVideos = {
    toolbar: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['code-block'],

        [{'size': ['small', false, 'large']}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        [{'color': []}, {'background': []}],
        [{'align': []}],

        ['link', 'image', 'video'],
      ],
      imageHandler: {
        accepts: ['png', 'jpg', 'jpeg'], // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
        upload: (file: File): Promise<string> => {
          this.uploadError = null;
          return new Promise((resolve, reject) => {
            if (this.uploadUrl !== null) {
              if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {

                this.form.removeControl('file');
                this.form.addControl('file', new FormControl(file));

                return this.api[this.uploadType](this.uploadUrl, this.form.value).then(
                  (response: ServerResponse): void => {
                    if (typeof response.data !== 'undefined') {
                      resolve(response.data.url);
                    } else {
                      this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                      reject('Ongeldige reactie.');
                    }
                  })
                  .catch((error: any): void => {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    console.error('Error:', error);
                    reject('Uploaden mislukt');
                  });
              } else {
                this.uploadError = 'Invalid file type!';
                reject('Niet ondersteund bestandstype');
                // Handle Unsupported type logic
              }
            } else {
              this.uploadError = 'No upload URL provided, upload is not available!';
              reject('No upload URL provided, upload is not available!');
            }
          });
        },
      } as Options,
      videoHandler: {
        accepts: ['mp4', 'webm'],  // Extensions to allow for videos (Optional) | Default - ['mp4', 'webm']
        upload: (file: File): Promise<string> => {
          this.uploadError = null;
          return new Promise((resolve, reject) => {
            if (this.uploadUrl !== null) {
              if (file.type === 'video/mp4' || file.type === 'video/webm') {

                this.form.removeControl('file');
                this.form.addControl('file', new FormControl(file));

                return this.api[this.uploadType](this.uploadUrl, this.form.value).then(
                  (response: ServerResponse): void => {
                    if (typeof response.data !== 'undefined') {
                      resolve(response.data.url);
                    } else {
                      this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                      reject('Ongeldige reactie.');
                    }
                  })
                  .catch((error: any): void => {
                    this.uploadError = 'Ongeldige reactie van de server. Probeer het opnieuw.';
                    console.error('Error:', error);
                    reject('Uploaden mislukt');
                  });
              } else {
                this.uploadError = 'Invalid file type!';
                reject('Niet ondersteund bestandstype');
                // Handle Unsupported type logic
              }
            } else {
              this.uploadError = 'No upload URL provided, upload is not available!';
              reject('No upload URL provided, upload is not available!');
            }
          });
        },
      } as Options
    },
    formats: [
      'background',
      'bold',
      'color',
      'code',
      'italic',
      'link',
      'size',
      'strike',
      'underline',
      'header',
      'list',
      'align',
      'code-block',
      'image',
      'video',
    ]
  };

  public quillEditor: any = null;
  public editorEnabled: boolean = true;

  constructor(private api: ApiService,
              private fb: FormBuilder,
  ) {
  }

  toggle(): void {
    this.editorEnabled = !this.editorEnabled;
  }

  maxLength(editor: any, length: number): void {
    if (editor.editor.getLength() > length) {
      editor.editor.deleteText(length, editor.editor.getLength());
    }
  }
}
