import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';
import { ScrollbarModule } from '../../../../@fury/shared/scrollbar/scrollbar.module';
import { EditorComponent } from './editor.component';
import { LoadingOverlayModule } from '../../../../@fury/shared/loading-overlay/loading-overlay.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuryAlertBoxModule } from '../../../../@fury/shared/alert-box/alert-box.module';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { LightboxModule } from 'ng-gallery/lightbox';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { RouterModule } from '@angular/router';
import { QuillModule } from 'ngx-quill';
import { ReportHistoryComponent } from './report-history/report-history.component';
import { RemovableCategoryComponent } from './removable-category/removable-category.component';
import { PageHeaderButtonsModule } from '../../../../@fury/shared/page-header-buttons/page-header-buttons.module';
import { ForwardReportDialogComponent } from './forward-report-dialog/forward-report-dialog.component';
import { ReassignReportDialogComponent } from './reassign-report-dialog/reassign-report-dialog.component';
import { ResendOpenConfirmationDialogComponent } from './resend-report-confirmation-dialog/resend-open-confirmation-dialog.component';
import { ConfirmLosingChangesDialogComponent } from './confirm-losing-changes-dialog/confirm-losing-changes-dialog.component';
import { TableFiltersModule } from '../../../../@fury/shared/table-filters/table-filters/table-filters.module';
import { MatChipsModule } from '@angular/material/chips';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxLoadingControlModule } from '@runette/ngx-leaflet-loading';
import { FindReporterDialogComponent } from './find-reporter-dialog/find-reporter-dialog.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ScrollbarModule,
    LoadingOverlayModule,
    ReactiveFormsModule,
    FuryAlertBoxModule,
    MtxDatetimepickerModule,
    LightboxModule,
    MaterialFileInputModule,
    RouterModule,
    QuillModule,
    PageHeaderButtonsModule,
    FormsModule,
    TableFiltersModule,
    MatChipsModule,
    LeafletModule,
    NgxLoadingControlModule,
    NgxMaskModule,
  ],
  declarations: [
    EditorComponent,
    ReportHistoryComponent,
    RemovableCategoryComponent,
    ForwardReportDialogComponent,
    ReassignReportDialogComponent,
    ResendOpenConfirmationDialogComponent,
    ConfirmLosingChangesDialogComponent,
    FindReporterDialogComponent,
  ],
  exports: [
    EditorComponent,
    ReportHistoryComponent,
    RemovableCategoryComponent,
    ForwardReportDialogComponent,
    ReassignReportDialogComponent,
    ResendOpenConfirmationDialogComponent,
    ConfirmLosingChangesDialogComponent,
    FindReporterDialogComponent,
  ]
})
export class EditorModule {
}
